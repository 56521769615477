import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { computed } from "mobx";
import { InterfaceInteractor } from "../../application/business/interactor/interface-interactor";
import { IPresenter } from "../../helpers/with-presenter";
import { DrawerVariant } from "../../config/interface-tools";

export class LayoutDefaultPresenter implements IPresenter {
  @computed public get user() {
    // return "test";
    return this._userInteractor.currentUser;
  }

  @computed public get interface() {
    return this._interfaceInteractor;
  }

  @computed public get activeMenuSpacing() {
    let width;

    switch (this._interfaceInteractor.activeToolConfig.variant) {
      case DrawerVariant.Overlay:
        width = 0;
        break;
      case DrawerVariant.Pusher:
      default:
        width = this._interfaceInteractor.activeToolConfig.width;
    }
    return width;
  }

  public mount() {
    //
  }
  public unmount() {
    //
  }

  public logout = () => {
    if (this.user) {
      this._userInteractor.logout();
    }
  };

  constructor(
    private _userInteractor: UserInteractor,
    private _interfaceInteractor: InterfaceInteractor
  ) {}
}
