import { IPresenter } from "../../helpers/with-presenter";
import { ImageProvider } from "../../application/data/image/image-provider";
import { InterfaceInteractor } from "../../application/business/interactor/interface-interactor";
import { Image } from "../../application/data/image/image";
import { observable, computed } from "mobx";
import { Collection } from "@coderehab/greenzine-react-deprecated";
import { ImageSelectorInteractor } from "../../application/business/interactor/image-selector-interactor";
export class ImageSelectorPresenter implements IPresenter {
  constructor(
    protected _interfaceInteractor: InterfaceInteractor,
    protected _imageProvider: ImageProvider,
    protected _imageSelectorInteractor: ImageSelectorInteractor
  ) {
    this.collection = this._imageProvider.collect();
  }

  @observable public collection: Collection<Image>;

  @computed public get active(): boolean {
    return this._imageSelectorInteractor.active;
  }

  @computed public get selectedImage(): string {
    return this._imageSelectorInteractor.selectedImage;
  }

  public async mount() {
    await this._imageProvider.fetchList();
  }

  public unmount() {
    // None
  }

  public close = this._imageSelectorInteractor.cancel;
  public select = this._imageSelectorInteractor.update;

  public onImageClick = (image: Image) => {
    this._imageSelectorInteractor.selectedImage = window.location.protocol + "//" + image.url;
  };

  public async updateAspectRatio(image: Image, aspect: any) {
    if (image.name === "Belasting.png") {
      console.log(image);
    }
    console.log("set aspect ratio to " + aspect);
    // image.aspect = aspect;

    // image.updateProperty("aspect", aspect);
    // image.save();
  }

  public onSearch = () => {};
  public onClickHandler(e: any) {
    alert("image selected");
  }
}
