import Amplify from "aws-amplify";
// import AuthTheme from "./view/theme/auth";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import { withAuthenticator } from "aws-amplify-react"; // or 'aws-amplify-react-native';
import { amplify } from "./config/amplify";
import Routes from "./view/routes";
import "./view/theme/react-grid-layout.css";
import "./view/theme/react-grid-resizable.css";
import { MediaLibrary } from "@coderehab/greenzeen-media-library";

Amplify.configure(amplify);

MediaLibrary.configure({
  api: {
    url: process.env.REACT_APP_MEDIA_LIBRARY_API_URL || "",
    key: process.env.REACT_APP_MEDIA_LIBRARY_API_KEY || "",
  },
});
// createMockDesigns();

@observer
class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Routes />
        </Router>
      </div>
    );
  }
}

export default App;
