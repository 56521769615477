import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { ArticleModel, Article } from "./article";
import * as Mutation from "./graphql/mutations";
import { graphqlOperation } from "aws-amplify";

export interface ArticleProvider extends Greenzine.ArticleProvider {
  //
}

export default class AdminArticleProvider extends Greenzine.DefaultArticleProvider
  implements ArticleProvider {
  public model = ArticleModel;

  public createOperation = (article: Article) => {
    return graphqlOperation(Mutation.CreateArticle, { input: article.serialize() });
  };
}
