import * as React from "react";
import { DefaultStyleAttributeEditor } from "./default";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import { observer } from "mobx-react";

@observer
export class BackgroundRepeatStyleEditor extends DefaultStyleAttributeEditor {
  public defaultValue: string = "initial";

  public render() {
    return (
      <>
        <InputLabel shrink id="select-backgroundrepeat-label">
          Background Repeat
        </InputLabel>
        <Select
          labelId="select-backgroundrepeat-label"
          fullWidth
          value={this.value?.toString() || "inherit"}
          onChange={this.onChange}
        >
          <MenuItem value={"initial"}>initial</MenuItem>
          <MenuItem value={"inherit"}>inherit</MenuItem>
          <MenuItem value={"repeat"}>repeat</MenuItem>
          <MenuItem value={"repeat-x"}>repeat-x</MenuItem>
          <MenuItem value={"repeat-y"}>repeat-y</MenuItem>
          <MenuItem value={"no-repeat"}>no-repeat</MenuItem>
        </Select>
      </>
    );
  }
}
