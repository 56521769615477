import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Magazine, MagazineData } from "@coderehab/greenzine-react-deprecated";
import React from "react";
import { mapEvent } from "../../../helpers/formatters";
import { IconButton } from "../../content/mui-extended/mui-icon-button";

interface OwnProps {
  magazines: Magazine[];
  onRowSelect?(id: string): void;
  onEdit?(id: string): void;
  onConfig?(id: string): void;
  onDelete?(id: string): void;
  onCreate?(data: MagazineData): void;
}

export const TableMagazines = function({
  magazines,
  onEdit,
  onConfig,
  onDelete,
  onCreate,
}: OwnProps) {
  return (
    <section>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding={"checkbox"} children={""} />
            {/* <TableCell padding={"checkbox"} children={""} /> */}
            <TableCell padding={"checkbox"} children={""} />
            <TableCell children={"title"} />
            <TableCell children={"slug"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {magazines.map((magazine) => {
            return (
              <TableRow key={magazine.id}>
                <TableCell
                  padding={"checkbox"}
                  children={
                    <IconButton onClick={mapEvent(onEdit, magazine.id)} icon="pencil-alt" />
                  }
                />
                {/* <TableCell
                  padding={"checkbox"}
                  children={<IconButton onClick={mapEvent(magazine.delete)} icon="trash-alt" />}
                /> */}
                <TableCell
                  padding={"checkbox"}
                  children={<IconButton onClick={mapEvent(onConfig, magazine.id)} icon="cog" />}
                />
                <TableCell children={magazine.title} />
                <TableCell children={magazine.slug} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
};
