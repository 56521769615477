import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { EditionModel } from "./edition";
import * as Mutation from "./graphql/mutations";
import { graphqlOperation } from "aws-amplify";

export interface EditionProvider extends Greenzine.EditionProvider {
  //
}

export default class AdminEditionProvider extends Greenzine.DefaultEditionProvider
  implements EditionProvider {
  public model = EditionModel;

  protected createOperation = (edition: any) => {
    return graphqlOperation(Mutation.CreateEdition, {
      input: { ...edition.serialize(), magazine: edition.magazineId },
    });
  };

  protected updateOperation = (edition: any) => {
    return graphqlOperation(Mutation.UpdateEdition, {
      input: { ...edition.serialize(), magazine: edition.magazineId },
    });
  };

  protected deleteOperation = (edition: any) => {
    return graphqlOperation(Mutation.DeleteEdition, {
      id: edition.id,
      magazine: edition.magazineId,
    });
  };
}
