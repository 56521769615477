import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { PagePresenter } from "./_base-page-presenter";

export class AuthPagePresenter extends PagePresenter {
  constructor(protected _userInteractor: UserInteractor, protected _router: RouteComponentProps) {
    super(_router);
  }

  @computed public get user() {
    return this._userInteractor.currentUser;
  }

  public mount = () => {};
  public unmount = () => {};
}
