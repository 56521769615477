import * as React from "react";
import { Grid, Typography, Theme, Button } from "@material-ui/core";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { typographyVariants } from "../theme/config";
import { StyleRules } from "@material-ui/core/styles";
import { WithStyles, withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "../content/mui-extended/mui-icon-button";
import { Link } from "react-router-dom";
import PageHeader from "../layout/partials/page-header";
import WidgetHeader from "../content/components/widget/widget-header";
import Widget from "../content/components/widget/widget";
import { PagePresenter } from "./_base-page-presenter";

type OwnProps = RouteComponentProps &
  WithStyles<any> &
  PresenterProps<PagePresenter>;

const styles = (theme: Theme): StyleRules => ({
  typography: {
    marginBottom: 10
  }
});

@observer
class AssetsComponent extends React.Component<OwnProps> {
  public render() {
    const { classes } = this.props;
    return (
      <Layout title={"Pentair Assets"}>
        <React.Fragment>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={typographyVariants.h2}>
                    Typography
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant={typographyVariants.h4}
                    className={classes.typography}
                  >
                    Default
                  </Typography>
                  <Typography
                    variant={typographyVariants.h1}
                    className={classes.typography}
                  >
                    Heading 1
                  </Typography>
                  <Typography
                    variant={typographyVariants.h2}
                    className={classes.typography}
                  >
                    Heading 2
                  </Typography>
                  <Typography
                    variant={typographyVariants.h3}
                    className={classes.typography}
                  >
                    Heading 3
                  </Typography>
                  <Typography
                    variant={typographyVariants.h4}
                    className={classes.typography}
                  >
                    Heading 4
                  </Typography>
                  <Typography
                    variant={typographyVariants.h5}
                    className={classes.typography}
                  >
                    Heading 5
                  </Typography>
                  <Typography
                    variant={typographyVariants.h6}
                    className={classes.typography}
                  >
                    Heading 6
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant={typographyVariants.h4}
                    className={classes.typography}
                  >
                    Body 1
                  </Typography>
                  <Typography
                    variant={typographyVariants.body1}
                    className={classes.typography}
                  >
                    8-bit mixtape man bun meggings, etsy sustainable hell of
                    pop-up sriracha readymade knausgaard shaman next level.
                    Cloud bread thundercats next level live-edge banh mi. +1
                    food truck try-hard bushwick pok pok, cronut selfies small
                    batch af organic. Photo booth hoodie taiyaki banh mi neutra,
                    craft beer cray. Synth hammock gluten-free, squid
                    farm-to-table cardigan narwhal. Keffiyeh direct trade synth
                    affogato shabby chic.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={typographyVariants.h4}
                    className={classes.typography}
                  >
                    Body 2
                  </Typography>
                  <Typography
                    variant={typographyVariants.body2}
                    className={classes.typography}
                  >
                    8-bit mixtape man bun meggings, etsy sustainable hell of
                    pop-up sriracha readymade knausgaard shaman next level.
                    Cloud bread thundercats next level live-edge banh mi. +1
                    food truck try-hard bushwick pok pok, cronut selfies small
                    batch af organic. Photo booth hoodie taiyaki banh mi neutra,
                    craft beer cray. Synth hammock gluten-free, squid
                    farm-to-table cardigan narwhal. Keffiyeh direct trade synth
                    affogato shabby chic.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant={typographyVariants.h2}>
                        Color palette
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            White
                          </Typography>
                          <div className={classes.white}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            background
                          </Typography>
                          <div className={classes.background}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            darkGray
                          </Typography>
                          <div className={classes.darkGray}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            silvergray
                          </Typography>
                          <div className={classes.silverGray}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            softgray
                          </Typography>
                          <div className={classes.softGray}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            darkgold
                          </Typography>
                          <div className={classes.darkGold}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            error
                          </Typography>
                          <div className={classes.error}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            impactgreen
                          </Typography>
                          <div className={classes.impactGreen}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            seafoamgreen
                          </Typography>
                          <div className={classes.seafoamGreen}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            green
                          </Typography>
                          <div className={classes.green}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            brightblue
                          </Typography>
                          <div className={classes.brightBlue}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            blue
                          </Typography>
                          <div className={classes.blue}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            khaki
                          </Typography>
                          <div className={classes.khaki}>
                            <FontAwesomeIcon
                              icon="paint-roller"
                              size="xs"
                              fixedWidth
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant={typographyVariants.h2}>
                        Buttons
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant={typographyVariants.h4}
                        className={classes.typography}
                      >
                        Icon button
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Default
                          </Typography>
                          <IconButton icon="question-circle"></IconButton>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Primary
                          </Typography>
                          <IconButton
                            variant="primary"
                            icon="question-square"
                          ></IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant={typographyVariants.h4}
                        className={classes.typography}
                      >
                        Button
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Contained default
                          </Typography>
                          <div>
                            <Button
                              component={Link}
                              variant="contained"
                              color="default"
                              to="/link"
                              endIcon={
                                <FontAwesomeIcon icon={["fal", "plus"]} />
                              }
                            >
                              Gimme bacon
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Outlined default
                          </Typography>
                          <Button
                            component={Link}
                            variant="outlined"
                            color="default"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Text default
                          </Typography>
                          <Button
                            component={Link}
                            variant="text"
                            color="default"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Contained primary
                          </Typography>
                          <Button
                            component={Link}
                            variant="contained"
                            color="primary"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Outlined primary
                          </Typography>
                          <Button
                            component={Link}
                            variant="outlined"
                            color="primary"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Text primary
                          </Typography>
                          <Button
                            component={Link}
                            variant="text"
                            color="primary"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Contained secondary
                          </Typography>
                          <Button
                            component={Link}
                            variant="contained"
                            color="secondary"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Outlined secondary
                          </Typography>
                          <Button
                            component={Link}
                            variant="outlined"
                            color="secondary"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant={typographyVariants.h6}
                            className={classes.typography}
                          >
                            Text secondary
                          </Typography>
                          <Button
                            component={Link}
                            variant="text"
                            color="secondary"
                            to="/link"
                            endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                          >
                            Gimme bacon
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={typographyVariants.h2}>
                    Widget component
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={typographyVariants.h4}
                    className={classes.typography}
                  >
                    Widget Header
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <WidgetHeader title="Without icon"></WidgetHeader>
                    </Grid>
                    <Grid item xs={6}>
                      <WidgetHeader
                        title="With icon"
                        icon="question-circle"
                      ></WidgetHeader>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={typographyVariants.h4}
                    className={classes.typography}
                  >
                    Widget
                  </Typography>
                  <Widget title="Widget">
                    <div>Mooie widget</div>
                  </Widget>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={typographyVariants.h4}
                    className={classes.typography}
                  >
                    Table widget
                  </Typography>
                  <Widget title="Table">
                    <div></div>
                  </Widget>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={typographyVariants.h2}>
                    Page header
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PageHeader>
                    <Button
                      component={Link}
                      variant="contained"
                      color="primary"
                      to="/create"
                      endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                    >
                      Create a new design
                    </Button>
                  </PageHeader>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Layout>
    );
  }
}

export const PageAssets = withRouter(
  withStyles(
    styles,
    {}
  )(
    withPresenter<PagePresenter, OwnProps>(
      (props, { business }) => new PagePresenter(props),
      AssetsComponent
    )
  )
);
