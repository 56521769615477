import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { PageModel } from "./page";
import * as Mutation from "./graphql/mutations";
import { graphqlOperation } from "aws-amplify";
import { removeNullValues } from "@coderehab/greenzine-react-deprecated";

export interface PageProvider extends Greenzine.PageProvider {}

export class AdminPageProvider extends Greenzine.DefaultPageProvider implements PageProvider {
  public model = PageModel;

  public createOperation = (page: any) => {
    return graphqlOperation(Mutation.CreatePage, {
      input: { ...removeNullValues(page.serialize(), true), article: page.articleId },
    });
  };

  public updateOperation = (page: any) => {
    return graphqlOperation(Mutation.UpdatePage, {
      input: { ...removeNullValues(page.serialize(), true), article: page.articleId },
    });
  };

  public deleteOperation = (page: any) => {
    return graphqlOperation(Mutation.DeletePage, {
      id: page.id,
      article: page.articleId,
    });
  };
}
