import * as React from "react";
import { DefaultStyleAttributeEditor } from "./default";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";

@observer
export class SpacingStyleEditor extends DefaultStyleAttributeEditor {
  public defaultValue: string | number = "none";

  public render() {
    return (
      <TextField
        fullWidth
        variant="standard"
        value={this.value}
        label={this.property}
        onChange={this.onChange}
      ></TextField>
    );
  }
}
