import { IPresenter } from "../../../helpers/with-presenter";
import { Page } from "../../../application/data/page/page";
import { observable, computed, toJS } from "mobx";
import { MagazineProvider } from "@coderehab/greenzine-react-deprecated";

import { LayoutEditorPresenter } from "../../content/editors/layout-editor-presenter";
import { Layout } from "react-grid-layout";
import { Themes } from "@coderehab/greenzeen-themes";
import { AnyElement } from "@coderehab/greenzeen-content";

export class ArticlePagePresenter implements IPresenter {
  @observable public isEditing: boolean = false;
  @observable public isPreview: boolean = false;
  @observable public breakpoint: "sm" | "md" | "lg" = "lg";
  @observable public isPublishing: boolean = false;
  @observable public rulerActive: boolean = false;

  @computed public get layout() {
    return this.page.stupidLayouts[this.breakpoint];
  }

  @computed public get elements() {
    return (this.page.elements.length ? this.page.elements : this.page.data) || [];
  }

  public editor: LayoutEditorPresenter | undefined = undefined;

  constructor(
    public page: Page,
    public theme: string,
    protected _pageProvider: any,
    protected __magazineProvider: MagazineProvider
  ) {
    Themes.configure({ tenant: theme });
    if (this.page.layout) {
      this.page.stupidLayouts.lg = this.page.layouts.lg || [];
      this.page.stupidLayouts.md = this.page.layouts.md || [];
      this.page.stupidLayouts.sm = this.page.layouts.sm || [];
    }
  }

  public mount = () => {};
  public unmount = () => {};

  public onDelete = (page: Page) => {
    page.delete();
  };

  public setEditor = (editor: LayoutEditorPresenter) => {
    this.editor = editor;
  };

  public startEditing = () => {
    this.isEditing = true;
  };

  public stopEditing = () => {
    this.isEditing = false;
  };

  public publishPage = async () => {
    this.isPublishing = true;
    await this.page.save();
    this.stopEditing();
    this.isPublishing = false;
  };

  public updateStyles = (style: React.CSSProperties) => {
    this.page.style = JSON.stringify(style);

    // const breakpoint = this.breakpoint || "lg";
    // // this.page.style.backgroundStyling[breakpoint]["backgroundSize"] = "100%";

    // // let responsiveStyling: any = {};

    // let currentStyling = JSON.parse(this.page.responsiveStyling);

    // currentStyling[breakpoint] = {
    //   backgroundSize: style.backgroundSize || "center",
    //   backgroundPosition: style.backgroundPosition || "center",
    //   backgroundRepeat: style.backgroundRepeat || "no-repeat",
    //   backgroundColor: style.backgroundColor || "transparent",
    //   backgroundImage: style.backgroundImage || "none",
    // };

    // this.page.responsiveStyling = JSON.stringify(currentStyling);

    // console.log("BACKGROUNDSTYLING", JSON.parse(this.page.responsiveStyling));
  };

  public onCreateElement = () => {
    if (this.editor) {
      const element = this.editor.createElement();
      if (this.breakpoint !== "lg") {
        this.prependLayoutConfig(this.page.stupidLayouts.lg, element);
      }

      if (this.breakpoint !== "md") {
        this.prependLayoutConfig(this.page.stupidLayouts.md, element);
      }

      if (this.breakpoint !== "sm") {
        this.prependLayoutConfig(this.page.stupidLayouts.sm, element);
      }
    }
  };

  public prependLayoutConfig = (layout: any, element: any) => {
    if (layout && layout.length && this.editor) {
      layout.splice(0, 0, {
        i: element.id,
        w: this.editor?.cols,
        h: -1,
        x: -1,
        y: -1,
      });

      // this.editor.onElementChange(toJS(layout));
    }
  };

  public onLayoutUpdated = (layout: Layout[] = [], elements: AnyElement[]) => {
    this.page.stupidLayouts[this.breakpoint] = layout;
    this.page.elements = elements;
  };

  public togglePreview = (e?: any) => {
    this.isPreview = !this.isPreview;
  };

  public toggleRuler = (e?: any) => {
    this.rulerActive = !this.rulerActive;
  };

  public getBreakpoint = () => {
    return this.breakpoint;
  };

  public setBreakpoint = (breakpoint: "sm" | "md" | "lg") => {
    const layout = this.page.stupidLayouts[breakpoint];

    if (!layout || !layout.length) {
      let lg = this.page.stupidLayouts["lg"];
      this.page.stupidLayouts[breakpoint] =
        lg &&
        toJS(lg).map((l: Layout) => {
          l.w = 24;
          l.maxW = 24;
          return l;
        });
    }

    this.breakpoint = breakpoint;
  };
}
