import { PollSubmission } from "@coderehab/greenzine-react-deprecated";
import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { Poll } from "../../../application/data/poll/poll";
import { PollProvider } from "../../../application/data/poll/poll-provider";
import { AuthPagePresenter } from "../_base-auth-page-presenter";

export type ProcessedAnswer = {
  summary: any[];
  totalSubmissions: number;
};

export class PollSinglePresenter extends AuthPagePresenter {
  @observable public loading: boolean = false;
  @observable public currentPoll: Poll | null = null;

  constructor(
    protected _userInteractor: UserInteractor,
    protected _pollProvider: PollProvider,
    protected _router: RouteComponentProps<{ poll?: string }>
  ) {
    super(_userInteractor, _router);
  }

  public mount = async () => {
    this.loading = true;

    let poll = null;
    const pollId = this._router.match.params.poll;

    if (pollId) {
      poll = this._pollProvider.get(pollId);
      await poll.fetch();
    }

    this.currentPoll = poll;
    this.loading = false;
  };

  public get allSubmissions() {
    if (!this.currentPoll) {
      return [];
    }
    const results = this.currentPoll.submissions.flat();
    return results;
  }

  public get sortedSubmissions() {
    if (!this.allSubmissions) {
      return;
    }

    const sortedSubmissions = this.allSubmissions.reduce(
      (sortedSubmissions: any, submission: PollSubmission) => {
        sortedSubmissions[submission.questionID] = sortedSubmissions[submission.questionID] || [];
        sortedSubmissions[submission.questionID].push(submission);
        return sortedSubmissions;
      },
      {}
    );

    return sortedSubmissions;
  }

  private sortToObject = (arrayToSort: any[], template = {}) => {
    const sortedObject = arrayToSort.reduce((sortedObject: any, answer: string) => {
      sortedObject[answer] = sortedObject[answer] || [];
      sortedObject[answer].push(answer);
      return sortedObject;
    }, template);

    return sortedObject;
  };

  private getQuestion = (questionID: string) => {
    const result =
      this.currentPoll &&
      this.currentPoll.questions.filter((question) => {
        return question.id === questionID;
      })[0];
    return result;
  };

  private processSubmission = (sortedSubmissions: PollSubmission[], questionID: any) => {
    this.loading = true;
    let sortedAnswers: any;
    let finalResult: any[] = [];
    let rawResult: any[] = [];
    let allAnswers: any[] = [];
    const totalSubmissions = sortedSubmissions.length;
    const possibleAnswers = this.getQuestion(questionID)?.options || [];

    // Put all answers to a question into a single array
    sortedSubmissions.map((submission: PollSubmission) => {
      allAnswers = allAnswers.concat(submission.answers);
      return allAnswers;
    });

    // Map every answer to a unique key in an object

    const template: any = possibleAnswers.reduce((template: any, option) => {
      template[option.toString()] = template[option.toString()] || [];
      return template;
    }, {});

    sortedAnswers = this.sortToObject(allAnswers, template);

    // Calculate answer popularity in percentages
    Object.keys(sortedAnswers).map((answer) => {
      const ratio = Math.round((sortedAnswers[answer].length / totalSubmissions) * 100);

      // Packs each answer and its popularity into an array
      rawResult.push({ answer: answer, ratio: ratio });

      // Sort DESC
      rawResult.sort((item, nextItem) => {
        return nextItem.ratio - item.ratio;
      });

      return rawResult;
    });

    const submissionType = this.getQuestion(questionID)?.type || "select";

    switch (submissionType) {
      case "rating":
        const totalRating: number = allAnswers.reduce((total: number, current: number) => {
          return +total + +current;
        });

        const avgRating = (totalRating / this.sortedSubmissions[questionID].length).toFixed(2);

        finalResult = [{ answer: avgRating, suffix: " - (Gemiddeld)" }];
        break;

      case "select":
      case "multiselect":
        finalResult = rawResult.map((item) => {
          return { answer: item.answer, suffix: " - (" + item.ratio + "%)" };
        });
        break;

      case "text":
        finalResult = rawResult;
        break;

      default:
        break;
    }

    const result: ProcessedAnswer = {
      summary: finalResult,
      totalSubmissions: totalSubmissions,
    };

    this.loading = false;

    return result;
  };

  public get results() {
    const result = Object.keys(this.sortedSubmissions).reduce((result: any, answer) => {
      result[answer] = result[answer] || [];
      result[answer] = this.processSubmission(this.sortedSubmissions[answer], answer);
      return result;
    }, {});

    return result;
  }
}
