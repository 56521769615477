import { Fade, Theme } from "@material-ui/core";
import { StyleRules, withStyles, WithStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { spacing } from "../theme/config";
import { LayoutDefaultPresenter } from "./layout-default-presenter";
import Footer from "./layout-footer";
import Header from "./layout-header-default";

interface OwnProps extends WithStyles<"root" | "content" | "header" | "footer"> {
  title?: string;
  subTitle?: string;
  noHeader?: boolean;
  header?: React.ReactElement;
  withContact?: boolean;
  children: React.ReactNode | React.ReactNode[];
  backgroundImage?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  titlePrefix?: string;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(4),
    transition: "padding-left 0.3s ",
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  header: {},
  content: {
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    marginTop: "auto"
  }
});

@observer
class Component extends React.Component<
  RouteComponentProps & OwnProps & PresenterProps<LayoutDefaultPresenter>
> {
  public render() {
    const {
      title,
      subTitle,
      presenter,
      children,
      classes,
      noHeader,
      style,
      titlePrefix,
      backgroundColor
    } = this.props;

    const inlineStyle: React.CSSProperties = {
      paddingLeft: `calc(${spacing(6)}px + ${presenter.activeMenuSpacing})` // presenter.interface.activeToolConfig.width spacing(6) + presenter.activeMenuSpacing
    };

    if (backgroundColor) {
      inlineStyle.backgroundColor = backgroundColor;
    }

    return (
      <div className={classes.root} style={inlineStyle}>
        <Fade in timeout={700}>
          <main className={classes.content} style={style}>
            {!noHeader && (
              <Header
                titlePrefix={titlePrefix}
                title={title}
                subTitle={subTitle}
                user={presenter.user}
              />
            )}
            {children}
            <Footer classes={{ root: classes.footer }} />
          </main>
        </Fade>
      </div>
    );
  }
}

export const DefaultLayout = withStyles(styles, {})(
  withRouter(
    withPresenter<LayoutDefaultPresenter, OwnProps & RouteComponentProps>(
      ({ history, match }, { interactor }) =>
        new LayoutDefaultPresenter(interactor.user, interactor.interface),
      Component
    )
  )
);
