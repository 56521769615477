import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { observable, toJS } from "mobx";
import { Layout } from "react-grid-layout";

import { AnyElement } from "@coderehab/greenzeen-content";

export interface Page extends Greenzine.Page {
  // articles: Article[];
  stupidLayouts: {
    sm: Layout[];
    md: Layout[];
    lg: Layout[];
  };
  elements: AnyElement[];
}

export class PageModel extends Greenzine.PageModel implements Page {
  @observable public stupidLayouts: any = {
    sm: [],
    md: [],
    lg: [],
  };

  @observable _elements: AnyElement[] | undefined;

  public get elements(): AnyElement[] {
    return this._elements || (this.record.elements && JSON.parse(this.record.elements)) || [];
  }

  public set elements(elements: AnyElement[]) {
    this._elements = elements;
  }

  public serialize = () => {
    const result: any = { ...this._values };

    result.layouts = {
      lg: toJS(this.stupidLayouts.lg).map((l: any) => {
        delete l.moved;
        return l;
      }),
      md: toJS(this.stupidLayouts.md).map((l: any) => {
        delete l.moved;
        return l;
      }),
      sm: toJS(this.stupidLayouts.sm).map((l: any) => {
        delete l.moved;
        return l;
      }),
    };

    result.elements = JSON.stringify((this.elements.length ? this.elements : this.data) || []);

    return result;
  };
}
