import { Theme, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { route } from "../../../config/routes";
import { mapEvent } from "../../../helpers/formatters";
import { IconButton } from "../../content/mui-extended/mui-icon-button";
import { InterfaceTools } from "./default-interface-presenter";

interface OwnProps extends WithStyles<"root"> {
  onToolSelect?(tool: InterfaceTools): void;
  active: InterfaceTools;
}

export const ToolBar = withStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: theme.palette.primary.main,
    "& > nav": {
      display: "flex",
      flexDirection: "column",
    },
  },
}))(
  observer(({ classes, onToolSelect, active }: OwnProps) => {
    return (
      <aside className={classes.root}>
        <nav>
          {/* <IconButton
            variant='primary'
            active={active === InterfaceTools.Menu}
            icon='bars'
            onClick={mapEvent(onToolSelect, InterfaceTools.Menu)}
          /> */}
          {/* {route("app.homepage") !== window.location.pathname && ( */}
          <IconButton variant="primary" icon="home" to={route("app.homepage")} />
          {/* )} */}
          <IconButton
            variant="primary"
            active={active === InterfaceTools.Notifications}
            icon="newspaper"
            to={route("app.magazine.overview")}
          />
          {/* <IconButton
            variant="primary"
            active={active === InterfaceTools.Notifications}
            icon="poll"
            to={route("app.poll.overview")}
          /> */}
        </nav>
        <nav>
          {/* <IconButton
            variant="primary"
            active={active === InterfaceTools.JSONeditor}
            icon="code"
            onClick={mapEvent(onToolSelect, InterfaceTools.JSONeditor)}
          /> */}
          <IconButton
            variant="primary"
            active={active === InterfaceTools.PageInfo}
            icon="question-circle"
            onClick={mapEvent(onToolSelect, InterfaceTools.PageInfo)}
          />
        </nav>
      </aside>
    );
  })
);
