import { IPresenter } from "@coderehab/greenzine-react-deprecated";
import { ImageSelectorInteractor } from "../../../application/business/interactor/image-selector-interactor";

export class StyleEditorPresenter implements IPresenter {
  constructor(
    private _changeHandler: (style: React.CSSProperties) => void,
    private _imageInteractor: ImageSelectorInteractor
  ) {
    //
  }

  public mount = () => {};
  public unmount = () => {
    this._imageInteractor.cancel();
  };

  public selectImage = this._imageInteractor.open;

  public onChange = (style: React.CSSProperties) => {
    this._changeHandler(style);
  };
}
