import { BaseSyntheticEvent } from "react";

export function mapEvent<T extends (...args: any[]) => any>(handler?: T, ...params: Parameters<T>) {
  return handler
    ? (e: BaseSyntheticEvent) => {
        console.log(e);
        return handler(
          ...params.map((param) => {
            return param && typeof param === "function" ? param(e) : param;
          })
        );
      }
    : undefined;
}

export function nodeValue(e: BaseSyntheticEvent): string | null {
  return e && e.target && (e.target.nodeValue || e.target.value);
}

export function nodeColorHex(e: any): string | null {
  return e && e.hex;
}

export function nodeJSON(e: any): any | null {
  if (e && e.jsObject) {
    return e.jsObject;
  }
}

export function nodeSliderValue(e: BaseSyntheticEvent): number | null {
  return e && e.target && (parseInt(e.target.innerText) || 300);
}
