import * as React from "react";
import { Typography, Theme, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// interface OwnProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: 12,
    marginBottom: 18,
    display: "flex"
  },
  body: {
    margin: "6px 0"
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexGrow: 0,
    flexShrink: 0
  }
}));

export const Notification = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6">Design approval request</Typography>

        <Typography variant="body1" gutterBottom className={classes.body}>
          K.pitchaikani has requested approval for design: Design01
        </Typography>

        <Typography variant="body1">
          <small>A few minutes ago.</small>
        </Typography>
      </div>
      <div className={classes.icons}>
        <FontAwesomeIcon size={"xs"} icon={["fas", "chevron-right"]} color={"#000"} />
        <FontAwesomeIcon icon={["fal", "exclamation-circle"]} color="#D31E1E" />
      </div>
    </div>
  );
};
