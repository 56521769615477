import { PageFragment } from "./fragments";

export const CreatePage = `
mutation CreatePage($input:inputPage!) {
createPage(input:$input){
  ${PageFragment}
}
}
`;

export const UpdatePage = `
mutation updatePage($input:inputPage!) {
  updatePage(input:$input) {
    ${PageFragment}
  }
}
`;

export const DeletePage = `
mutation DeletePage($id:ID!, $article:ID!) {
  deletePage(id:$id, article:$article){
    ${PageFragment}
  }
}
`;
