import * as React from "react";
import { DefaultStyleAttributeEditor } from "./default";
import { observer } from "mobx-react";
import { ColorPicker } from "@coderehab/greenzeen-themes";
import { FormLabel } from "@material-ui/core";

@observer
export class ColorStyleEditor extends DefaultStyleAttributeEditor {
  public defaultValue = "";

  public render() {
    return (
      <>
        <FormLabel style={{ fontSize: 12 }}>{this.property}</FormLabel>
        <ColorPicker
          value={(this.value || "").replace("url('", "").replace("')")}
          onChange={(color) => this.updateValue(color)}
        />
      </>
    );
  }
}
