import { StyleRules, TableRow, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { ProcessedAnswer } from "../../pages/poll/poll-single-presenter";

interface OwnProps extends WithStyles<any> {
  result: ProcessedAnswer;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  inner: {
    marginTop: theme.spacing(1),
  },
  collapse: {
    marginLeft: theme.spacing(1),
  },
  smallAnswer: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    // "&:nth-child(odd)": {
    backgroundColor: "#f2f2f2",
    // },
  },
  collapseWindow: {
    paddingTop: theme.spacing(1),
  },
});

const PollQuestionResultComponent = (props: OwnProps) => {
  const { classes, result } = props;
  const [collapsed, toggleCollapse] = React.useState(true);

  return (
    <div className={classes.root} onClick={() => toggleCollapse(!collapsed)}>
      {result &&
        result.summary.map((item: any) => {
          return (
            <TableRow className={classes.smallAnswer}>
              <Typography>{item.answer + (item.suffix ? item.suffix : "")}</Typography>
              {/* <FontAwesomeIcon
          className={classes.collapse}
          icon={collapsed ? faChevronDown : faChevronUp}
        /> */}
            </TableRow>
          );
        })}
      {/* <Collapse in={!collapsed} className={classes.collapseWindow}>
        {result.extended.map(answer => {
          return <Typography>{answer}</Typography>
        })}
      </Collapse> */}
    </div>
  );
};

export const PollQuestionResult = withStyles(styles)(PollQuestionResultComponent);
