import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { PollData } from "@coderehab/greenzine-react-deprecated";
import { observer } from "mobx-react";
import React from "react";
import { Poll } from "../../../application/data/poll/poll";
import { mapEvent } from "../../../helpers/formatters";
import { IconButton } from "../mui-extended/mui-icon-button";

interface OwnProps {
  polls?: Poll[];
  onRowSelect(id: string): void;
  onEdit(id: string): void;
  onDelete(id: string): void;
  onCreate(data: PollData): void;
  poll?: any;
}

export const TablePolls = observer(function({ polls, onEdit, onDelete, onCreate, poll }: OwnProps) {
  return (
    <section>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding={"checkbox"} children={""} />
            <TableCell padding={"checkbox"} children={""} />
            <TableCell children={"id"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {polls &&
            polls.map((poll) => {
              return (
                <TableRow key={poll.id}>
                  <TableCell
                    padding={"checkbox"}
                    children={<IconButton onClick={mapEvent(onEdit, poll.id)} icon="pencil-alt" />}
                  />
                  <TableCell
                    padding={"checkbox"}
                    children={<IconButton onClick={mapEvent(poll.delete)} icon="trash-alt" />}
                  />
                  <TableCell children={poll.attributes.id} />
                </TableRow>
              );
            })}
          {poll && (
            <TableRow key={poll.id}>
              <TableCell
                padding={"checkbox"}
                children={<IconButton onClick={mapEvent(onEdit, poll.id)} icon="pencil-alt" />}
              />
              <TableCell
                padding={"checkbox"}
                children={<IconButton onClick={mapEvent(poll.delete)} icon="trash-alt" />}
              />
              <TableCell children={poll.id} />
              {console.log(poll)}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </section>
  );
});
