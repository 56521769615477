import { ArticleFragment } from "../../article/graphql/fragments";

// import { ArticleFragment } from "../../article/graphql/fragments";

export const EditionFragment = `
    id
    slug
    title
    public
    description 
    image
    articles {
      ${ArticleFragment}
    }
`;
