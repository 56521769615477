import { generatePath, RouteComponentProps } from "react-router";
import { PageArticleOverview } from "../view/pages/article/article-index";
import { PageArticleSingle } from "../view/pages/article/article-single";
import { PageEditionOverview } from "../view/pages/edition/edition-index";
import { Error404 } from "../view/pages/error-404";
import { PageHome } from "../view/pages/home";
import { PageMagazineOverview } from "../view/pages/magazine/magazine-index";
import { PollOverview } from "../view/pages/poll/poll-overview";
import { PollSingle } from "../view/pages/poll/poll-single";
// import { PageManage } from "../view/pages/manage";
import { PageAssets } from "../view/pages/theme-assets";
import { PageUserOverview } from "../view/pages/user/user-overview";
import { Disclaimer } from "../view/pages/disclaimer";
import { TermsOfService } from "../view/pages/terms-of-service";
import { PageMagazineConfig } from "../view/pages/magazine/magazine-config";

export interface IRoute {
  path: string;
  title: string;
  component: any;
  mainmenu?: boolean;
  disabled?: boolean;
  public?: boolean;
  redirect?: string;
}

export const redirections = [{ from: "/", to: "/dashboard" }];

export const routeMap = {
  "app.homepage": {
    path: "/",
    title: "Greenzeen management app",
    component: PageHome,
    mainmenu: true,
  },
  "app.user.overview": {
    path: "/users",
    title: "Users overview",
    component: PageUserOverview,
    mainmenu: true,
  },
  "app.magazine.overview": {
    path: "/magazines",
    title: "Magazine overview",
    component: PageMagazineOverview,
    mainmenu: true,
  },
  "app.magazine.config": {
    path: "/magazines/:magazine/config",
    title: "Magazine configuration",
    component: PageMagazineConfig,
    mainmenu: true,
  },
  "app.magazine.single": {
    path: "/magazine/:magazine",
    title: "Magazine overview",
    component: PageMagazineOverview,
    mainmenu: true,
  },
  "app.edition.overview": {
    path: "/magazine/:magazine/editions",
    title: "Magazine overview",
    component: PageEditionOverview,
    mainmenu: true,
  },
  "app.edition.single": {
    path: "/article/:magazine/:edition",
    title: "Arrticle overview",
    component: PageArticleOverview,
    mainmenu: true,
  },
  "app.edition.article.overview": {
    path: "/magazine/:magazine/:edition/articles",
    title: "Magazine overview",
    component: PageArticleOverview,
    mainmenu: true,
  },
  "app.magazine.article.overview": {
    path: "/magazine/:magazine/articles",
    title: "Article overview",
    component: PageArticleOverview,
    mainmenu: true,
  },

  "app.article.single": {
    path: "/magazine/:magazine/articles/article/:article",
    title: "Article overview",
    component: PageArticleSingle,
    mainmenu: true,
  },
  "app.poll.overview": {
    path: "/polls",
    title: "Polls overview",
    component: PollOverview,
    mainmenu: true,
    public: true,
  },
  "app.poll.show": {
    path: "/polls/:poll",
    title: ":poll",
    component: PollSingle,
    mainmenu: true,
  },
  "app.disclaimer": {
    path: "/app/disclaimer",
    title: "Disclaimer",
    component: Disclaimer,
  },
  "app.terms-of-service": {
    path: "/app/terms-of-service",
    title: "Terms of service",
    component: TermsOfService,
  },
  "app.privacy.policy": {
    path: "/app/privacy-policy",
    title: "Privacy policy",
    component: Error404,
  },
  "docs.assets": {
    path: "/assets",
    title: "Theme Assets",
    component: PageAssets,
  },
  refresh: {
    path: "/refresh",
    title: "404 - Resource not found",
    component: ({ history }: RouteComponentProps) => {
      history.goBack();

      return "";
    },
    public: true,
  },
  "error.404": {
    path: "*",
    title: "404 - Resource not found",
    component: Error404,
    public: true,
  },
};

export type RouteNames = keyof typeof routeMap;

export const route = (name: RouteNames, args?: Record<string, string | boolean | number>) => {
  return generatePath(routeMap[name].path, args);
};

export const routes: IRoute[] = Object.keys(routeMap).map((name: string) => {
  return routeMap[name as RouteNames];
});

export const checkRedirect = () =>
  redirections.filter(({ from }) => from === window.location.pathname.replace(/\/$/, ""))[0];
