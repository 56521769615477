const LayoutFragment = `
  i
  x
  y
  w
  h
  minW
  maxW
  minH
  maxH
  static
  isDraggable
  isResizable
`;

export const PageFragment = `
  id
  title
  slug
  background
  type
  filter
  overlay
  color
  elements
  layout
  layoutConfig
  data {
    id
    section
    component
    props
    style
  }
  style
  responsiveStyling
  layouts {
    sm {
      ${LayoutFragment}
    }
    md {
      ${LayoutFragment}
    }
    lg {
      ${LayoutFragment}
    }
  }
`;
