import * as React from "react";
import { observer } from "mobx-react";
import { withStyles, WithStyles, StyleRules, Theme } from "@material-ui/core";

interface OwnProps extends WithStyles<"root"> {
  active?: boolean;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "block",
    width: 2,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "calc(50% - 1px)",
    backgroundColor: "rgba(0,0,0,1)",
    zIndex: 1,
    "&::after": {
      content: "' '",
      display: "block",
      position: "relative",
      width: 10,
      //   height: 50,
      backgroundColor: "rgba(244, 244, 244, 0.3)",
      top: 0,
      left: -4,
      zIndex: 0,
      height: "100%",
    },
  },
});

export const Ruler = withStyles(styles)(
  observer(({ active, classes }: OwnProps) => {
    return <div className={classes.root} style={{ display: active ? "block" : "none" }}></div>;
  })
);
