import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { ImageModel, ImageRecord } from "./image";
import * as Query from "./graphql/queries";
import { graphqlOperation } from "aws-amplify";

export interface ImageProvider extends Greenzine.GraphQLProviderProps<ImageModel, ImageRecord> {
  //
}

export default class AdminImageProvider extends Greenzine.GraphQLProvider<ImageModel, ImageRecord>
  implements ImageProvider {
  public model = ImageModel;

  protected listOperation = (variables: Record<string, any> = {}) => {
    return graphqlOperation(Query.ImageCollection, variables);
  };
}
