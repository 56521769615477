import {
  CircularProgress,
  StyleRules,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import Widget from "../../content/components/widget/widget";
import { DefaultLayout } from "../../layout/layout-default";
import { PollQuestionBlock } from "../../layout/partials/poll-question";
import { PollSinglePresenter } from "./poll-single-presenter";

type OwnProps = RouteComponentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    maxWidth: "600px",
    margin: "0 auto"
  }
});

const pollSingleComponent = withStyles(styles)(
  observer(
    ({
      classes,
      presenter
    }: OwnProps & PresenterProps<PollSinglePresenter>) => {
      const currentPoll = presenter.currentPoll;

      return (
        <DefaultLayout
          titlePrefix={"Greenzine"}
          title={"Poll"}
          subTitle={"overview"}
        >
          <div className={classes.root}>
            <Widget title={currentPoll?.name || "Bondig poll"}>
              <Table>
                <TableHead></TableHead>
                <TableBody>
                  {presenter.loading && (
                    <TableRow
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <CircularProgress />
                    </TableRow>
                  )}
                  {currentPoll?.questions.map((question, index) => {
                    return (
                      <PollQuestionBlock
                        presenter={presenter}
                        question={question}
                        currentPoll={currentPoll}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Widget>
          </div>
        </DefaultLayout>
      );
    }
  )
);

export const PollSingle = withRouter(
  withPresenter<PollSinglePresenter, RouteComponentProps>(
    (props, { interactor, provider }) =>
      new PollSinglePresenter(interactor.user, provider.poll, props),
    pollSingleComponent
  )
);
