import * as React from "react";

import { withStyles, WithStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { route } from "../../config/routes";

type OwnProps = WithStyles<"root">;

export default withStyles(
  {
    root: {
      textAlign: "right",
      color: "#C4BFB6",
      fontSize: "14px",
      paddingTop: 24,
      marginBottom: -12,
      "& a": {
        color: "#C4BFB6",
        padding: "6px",
        transition: "color 0.2s",
        "&:hover": {
          color: "#000"
        }
      }
    }
  },
  {}
)(function DefaultPageFooter({ classes }: OwnProps) {
  return (
    <footer className={classes.root}>
      ©{new Date().getFullYear()} Code.Rehab. All rights reserved.
      <br />
      <Link
        to={route("app.disclaimer")}
        // to={window.location.pathname}
      >
        Disclaimer
      </Link>{" "}
      |{" "}
      <Link
        to={route("app.terms-of-service")}
        // to={window.location.pathname}
      >
        Terms of service
      </Link>{" "}
      |{" "}
    </footer>
  );
});
