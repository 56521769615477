import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";

interface SortableItemProps {
  id?: string;
  title?: string;
  name?: string;
}

interface SortableListProps {
  items: SortableItemProps[];
}

export const SortableItem = SortableElement(({ item }: { item: SortableItemProps }) => (
  <div style={{ backgroundColor: "#eee", padding: 12, marginBottom: 12, cursor: "grab" }}>
    <FontAwesomeIcon icon={faGripVertical} size={"sm"} style={{ opacity: 0.5, marginRight: 6 }} />
    {item.title || item.name || ""}
  </div>
));
export const SortableList = SortableContainer(({ items }: SortableListProps) => {
  return (
    <div>
      {items.map((item: SortableItemProps, index: number) => (
        <SortableItem key={`item-${item.id || index}`} index={index} item={item} />
      ))}
    </div>
  );
});
