export const Container = {
  display: "flex",
  alignItems: "center",
  height: "100%",
  minHeight: "100vh",
  backgroundColor: "#073B3A",
  backgroundImage: "url('/images/landingpage.jpg' )",
  backgroundSize: "cover",
  backgroundPosition: "center",
};
export const FormContainer = {
  minWidth: "auto",
  margin: "0 auto",
  borderRadius: "0",
};
export const FormSection = {};
export const FormField = {};
export const SectionHeader = {
  before: {
    content: "Greenzeen",
    position: "relative",
    display: "block",
    bottom: "calc(100% + 30px)",
    fontSize: "calc(1.5em + 1vw)",
    fontWeight: 700,
    color: "#073B3A",
    marginBottom: 30,
  },
};
export const SectionBody = {};
export const SectionFooter = {};
export const SectionFooterPrimaryContent = {};
export const SectionFooterSecondaryContent = {};
export const Input = {};
export const Button = {
  backgroundColor: "#073B3A",
  minWidth: "120px",
};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {
  color: "#073B3A",
  marginRight: "20px",
};
export const Hint = {};
export const Radio = {};
export const InputLabel = {};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {};
export const NavBar = {};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

const AuthTheme = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  photoPickerButton: PhotoPickerButton,
  photoPlaceholder: PhotoPlaceholder,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  amazonSignInButton: AmazonSignInButton,
  facebookSignInButton: FacebookSignInButton,
  googleSignInButton: GoogleSignInButton,
  oAuthSignInButton: OAuthSignInButton,

  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,

  hint: Hint,
  radio: Radio,
  inputLabel: InputLabel,
  toast: Toast,

  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton,
};

export default AuthTheme;
