import { EditionFragment } from "./fragments";

export const CreateEdition = `
mutation CreateEdition($input:inputEdition!) {
createEdition(input:$input){
  ${EditionFragment}
}
}
`;

export const UpdateEdition = `
mutation updateEdition($input:inputEdition!) {
  updateEdition(input:$input) {
    ${EditionFragment}
  }
}
`;

export const DeleteEdition = `
mutation DeleteEdition($id:ID!, $magazine:ID!) {
  deleteEdition(id:$id, magazine:$magazine){
    ${EditionFragment}
  }
}
`;
