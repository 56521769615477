import { Table, TableBody, TableCell, TableHead, TableRow, Button, Switch } from "@material-ui/core";
import { Edition } from "@coderehab/greenzine-react-deprecated";
import React from "react";
import { mapEvent } from "../../../helpers/formatters";
import { IconButton } from "../../content/mui-extended/mui-icon-button";

interface OwnProps {
  editions: Edition[];
  onRowSelect?(id: string): void;
  onEdit?(id: string): void;
  onDelete?(id: string): void;
  onCreate?(): void;
  onSetPublic?(id: string): void;
}


export const TableEditions = function({ editions, onEdit, onDelete, onCreate, onSetPublic }: OwnProps) {
  return (
    <section>
      <Button variant={"contained"} color={"primary"} onClick={mapEvent(onCreate)}>
        Create edition
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding={"checkbox"} children={""} />
            {/* <TableCell padding={"checkbox"} children={""} /> */}
            <TableCell children={"published?"} />
            <TableCell children={"title"} />
            <TableCell children={"slug"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {editions.map((edition) => {
            
            return (
              <TableRow key={edition.id}>
                <TableCell
                  padding={"checkbox"}
                  children={<IconButton onClick={mapEvent(onEdit, edition.id)} icon="pencil-alt" />}
                />
                {/* <TableCell
                  padding={"checkbox"}
                  children={
                    <IconButton onClick={mapEvent(onDelete, edition.id)} icon="trash-alt" />
                  }
                /> */}
                <TableCell
                  children={
                    <>
                      <Switch
                      key={edition.id}
                      checked={(edition.public === "true") ? true : false }
                      size="small" 
                      onChange={mapEvent(onSetPublic, edition.id)}
                      name="checkedB"
                      color="primary"
                      style={{color: "#ccc", width: "auto", height: "auto"}}
                    />
                    {/* <span>{edition.public}</span> */}
                  </>
                  } />

                <TableCell children={edition.title} />
                <TableCell children={edition.slug} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
};
