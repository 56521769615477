import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton as MuiIconButton, makeStyles, Theme } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

interface OwnProps extends Partial<IconButtonProps> {
  icon: IconName;
  active?: boolean;
  variant?: "default" | "primary";
  onClick?(e: React.MouseEvent): void;
  to?: string;
}

const useStyles = (props: OwnProps) => {
  return makeStyles((theme: Theme) => ({
    root: {
      fontSize: 18,
      borderRadius: 0,
      "&:hover": {
        "& $icon": {
          "& >:first-child": {
            opacity: 0
          },
          "& >:last-child": {
            opacity: 1
          }
        }
      }
    },
    icon: {
      color: "#000",
      position: "relative",
      "& >:first-child": {
        opacity: 1,
        position: "absolute",
        transform: "translate(-50%, -50%)"
      },
      "& >:last-child": {
        opacity: 0,
        position: "absolute",
        transform: "translate(-50%, -50%)"
      },
      "&$primary": {
        color: "#fff"
      }
    },
    default: {
      color: "#000",
      backgroundColor: "inherit",

      "& $icon": {
        "& >:last-child": {
          color: "#555"
        }
      },

      "&:hover": {
        "& $icon": {
          backgroundColor: "#555"
        }
      }
    },
    primary: {
      color: "#fff",
      backgroundColor: theme.palette.primary.main
    },
    active: {
      "&$primary": {
        backgroundColor: "#fff"
      },
      "& $icon": {
        "& >:first-child": {
          opacity: 0
        },
        "& >:last-child": {
          opacity: 1,
          color: "#555"
        }
      }
    }
  }));
};
export function IconButton(props: OwnProps) {
  const classes = useStyles(props)();
  const variant = props.variant || "default";

  return props.to ? (
    <Link to={props.to || ""} onClick={props.onClick}>
      <MuiIconButton
        className={classNames(classes.root, props.active && classes.active, classes[variant])}
        {...props}
      >
        <div className={classNames(classes.icon, classes[variant])}>
          <FontAwesomeIcon icon={["fal", props.icon]} />
          <FontAwesomeIcon icon={["fas", props.icon]} />
        </div>
      </MuiIconButton>
    </Link>
  ) : (
    <MuiIconButton
      className={classNames(classes.root, props.active && classes.active, classes[variant])}
      {...props}
    >
      <div className={classNames(classes.icon, classes[variant])}>
        <FontAwesomeIcon icon={["fal", props.icon]} />
        <FontAwesomeIcon icon={["fas", props.icon]} />
      </div>
    </MuiIconButton>
  );
}
