import { ArticleFragment } from "./fragments";

export const CreateArticle = `
mutation CreateArticle($input:inputArticle!) {
createArticle(input:$input){
  ${ArticleFragment}
}
}
`;

export const UpdateArticle = `
mutation updateArticle($input:inputArticle!) {
  updateArticle(input:$input) {
    ${ArticleFragment}
  }
}
`;

export const DeleteArticle = `
mutation DeleteArticle($id:ID!) {
  deleteArticle(id:$id){
    ${ArticleFragment}
  }
}
`;
