import * as React from "react";
import { Button, StyleRules, Theme, withStyles, WithStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { ArticleSinglePresenter } from "../article-single-presenter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

interface OwnProps extends WithStyles<"root"> {
  presenter: ArticleSinglePresenter;
}

const styles = (theme: Theme): StyleRules => ({
  root: { padding: theme.spacing(2, 0) }
});

export const ArticleHeader = withStyles(styles)(
  observer(({ presenter, classes }: OwnProps) => {
    const { startEditing, createPage } = presenter;

    return (
      <div className={classes.root}>
        <Button onClick={startEditing} color={"primary"} variant={"contained"}>
          Edit article
        </Button>
        <Button
          onClick={createPage}
          color={"secondary"}
          variant={"contained"}
          startIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Create page
        </Button>
      </div>
    );
  })
);
