import * as React from "react";
import { Notification } from "./partials/notification";
import { Fade, Slide } from "@material-ui/core";

export const DrawerNotifications = () => {
  return (
    <div>
      {[1].map((notification, index) => (
        <Fade key={index} in style={{ transitionDelay: `${0.03 * index + 0.1}s` }}>
          <Slide
            in
            direction={"right"}
            style={{
              transitionDelay: `${0.03 * index + 0.1}s`
            }}
          >
            <div>
              <Notification />
            </div>
          </Slide>
        </Fade>
      ))}
    </div>
  );
};
