import { UserInteractor, UserProvider } from "@coderehab/greenzine-react-deprecated";
import { observable } from "mobx";
import { IPresenter } from "../helpers/with-presenter";

export class AuthPresenter implements IPresenter {
  @observable public loading: boolean = true;

  constructor(private _userInteractor: UserInteractor, private _userProvider: UserProvider) {
    //
  }

  public mount = async () => {
    try {
      if (this._userInteractor.currentUser) {
        this.loading = false;
      }
    } catch (err) {
      await this.loadUser();
    }
  };

  public unmount = () => {};

  public loadUser = async () => {
    this.loading = true;
    const user = await this._userProvider.fetchAuthUser();
    this._userInteractor.currentUser = user;
    this.loading = false;
  };
}
