import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import LoadingIcon from "../../content/components/loading";
import { DefaultLayout as Layout } from "../../layout/layout-default";
import { ArticleSinglePresenter } from "./article-single-presenter";
import { StyleRules, Theme, withStyles, WithStyles } from "@material-ui/core";

import { ArticleSettings } from "./partials/article-settings";
import { ArticlePages } from "./partials/article-pages";
import { ArticleHeader } from "./partials/article-header";

type OwnProps = RouteComponentProps & WithStyles<"root" | "pageWrapper" | "pageRoot">;

const styles = (theme: Theme): StyleRules => ({
  root: {},
});

const Component = withStyles(styles)(
  observer(({ presenter, classes }: OwnProps & PresenterProps<ArticleSinglePresenter>) => {
    const { loading, article, theme } = presenter;

    if (loading) {
      return <LoadingIcon />;
    }

    return (
      <div className={classes.root}>
        <Layout titlePrefix={"Article"} title={article.title}>
          <ArticleHeader presenter={presenter} />
          <ArticlePages presenter={presenter} theme={theme || "default"} />
          <ArticleSettings presenter={presenter} />
        </Layout>
      </div>
    );
  })
);

export const PageArticleSingle = withRouter(
  withPresenter<ArticleSinglePresenter, RouteComponentProps>(
    (props, { interactor, provider }) =>
      new ArticleSinglePresenter(
        provider.article,
        provider.magazine,
        provider.page,
        interactor.json,
        interactor.user,
        interactor.interface,
        props
      ),
    Component
  )
);
