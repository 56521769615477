import createSpacing from "@material-ui/core/styles/createSpacing";

export const spacing = createSpacing(12);

export const typographyVariants = {
  h1: "h1" as "h1",
  h2: "h2" as "h2",
  h3: "h3" as "h3",
  h4: "h4" as "h4",
  h5: "h5" as "h5",
  h6: "h6" as "h6",
  body1: "body1" as "body1",
  body2: "body2" as "body2"
};

export const themeConfig: any = {
  spacing: 12,
  typography: {
    body1: { fontSize: "1rem" },
    body2: { fontSize: "0.875rem" },
    h1: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 700,
      color: "#000",
      fontSize: "2.125rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    h2: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#000",
      fontSize: "1.5625rem",
      lineHeight: "1.2"
    },
    h3: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#000",
      fontSize: "1.25rem",
      lineHeight: "1.2"
    },
    h4: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#000",
      fontSize: "1rem",
      lineHeight: "1.2"
    },
    h5: {
      fontWeight: 600,
      fontFamily: "IBM Plex Sans",
      textTransform: "uppercase",
      color: "#000",
      fontSize: "0.875rem",
      lineHeight: "1.2"
    },
    h6: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#000",
      fontSize: "0.75rem",
      lineHeight: "1.2"
    },
    subtitle1: {},
    subtitle2: {},
    caption: {},
    overline: {},
    button: {
      fontWeight: 600,
      lineHeight: 1,
      textTransform: "uppercase",
      textDecoration: "none"
    }
  }
};

export const typography = (key: any) => {
  return themeConfig.typography[key] as any;
};
