import {
  User,
  UserInteractor,
  UserProvider,
  UserRecord,
} from "@coderehab/greenzine-react-deprecated";
import { observable, toJS } from "mobx";
import { RouteComponentProps } from "react-router";
import { IPresenter } from "../../../helpers/with-presenter";
import { AuthPagePresenter } from "../_base-auth-page-presenter";

export class UserPresenter extends AuthPagePresenter implements IPresenter {
  @observable public selectedUser: User | null = null;
  @observable public createValues: UserRecord = {
    username: "",
    attributes: {},
  } as UserRecord;

  constructor(
    protected _userInteractor: UserInteractor,
    protected _userProvider: UserProvider,
    protected _router: RouteComponentProps<{ design?: string }>
  ) {
    super(_userInteractor, _router);

    this.load();
  }

  public load = async () => {
    this._userProvider.fetchList();
    // this.loading = true;
    // await this._designInteractor.load();
    // this._designInteractor.select(this._router.match.params.design || "");
    // this.loading = false;
  };

  public mount = () => {};

  public get allUsers() {
    return this._userProvider.collect().items;
  }

  public createUser = (data: UserRecord) => {
    this._userProvider.create(toJS(data), true);
    this.resetForm();
  };

  public updateUser = (data: UserRecord) => {
    if (this.selectedUser) {
      this.selectedUser.updateProperty("attributes", data.attributes);
      this.selectedUser.save();
      this.resetForm();
    }
  };

  public unmount = () => {
    //
  };

  public selectUser = (id: string) => {
    this.selectedUser = this._userProvider.get(id);
  };

  public resetForm = () => {
    this.selectedUser = null;
    this.createValues = {
      username: "",
      attributes: {},
    } as UserRecord;
  };
}
