import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { CanView } from "../../content/components/can-view/can-view";
import Widget from "../../content/components/widget/widget";
import { DefaultLayout as Layout } from "../../layout/layout-default";
import { MagazinePresenter } from "./magazine-presenter";
import { TableMagazines } from "./magazine-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

type OwnProps = RouteComponentProps;

const UnauthorizedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        opacity: 0.3
      }}
    >
      <Typography variant={"h1"} style={{ fontSize: "calc(2em + 1vw)" }}>
        Unauthorized
      </Typography>
      <Typography variant={"h5"}>you are not allowed to view this page</Typography>
    </div>
  );
};

@observer
class MagazineOverviewComponent extends React.Component<OwnProps & PresenterProps<MagazinePresenter>> {
  public render() {
    const { magazines, selectMagazine,configureMagazine, loading } = this.props.presenter;

    return (
      <Layout titlePrefix={"Magazine"} title={"overview"}>
        <CanView notAuthorized={<UnauthorizedPage />}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Widget title="Magazine overview">
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin={true} size={"lg"} />
                ) : (
                  <TableMagazines
                    onRowSelect={selectMagazine}
                    onEdit={selectMagazine}
                    onConfig={configureMagazine}
                    onDelete={() => {}}
                    magazines={magazines}
                  />
                )}
              </Widget>
            </Grid>
          </Grid>
        </CanView>
      </Layout>
    );
  }
}

export const PageMagazineOverview = withRouter(
  withPresenter<MagazinePresenter, RouteComponentProps>(
    (props, { interactor, provider }) => new MagazinePresenter(provider.magazine, interactor.user, props),
    MagazineOverviewComponent
  )
);
