import * as React from "react";

import { observer } from "mobx-react";
import { DataEditorPresenter } from "./data-editor-presenter";

import {
  FormTypographyElement,
  FormQuoteElement,
  FormWistJeDatElement,
  ElementForm,
  ElementType,
  AnyElement,
  FormJson,
  FormAuthorElement,
  FormCoverButtonElement,
  FormImageElement,
  FormListElement,
  FormReadTimeElement,
  FormVideoElement,
  FormIndexElement,
  FormButtonElement,
  FormPopupElement
  // FormPopupElement
} from "@coderehab/greenzeen-content";

interface OwnProps {
  data: AnyElement;
  forceJson: boolean;
  presenter: DataEditorPresenter;
}

const Forms: Record<ElementType, ElementForm<AnyElement>> = {
  Typography: FormTypographyElement,
  Quote: FormQuoteElement,
  WistJeDat: FormWistJeDatElement,
  Author: FormAuthorElement,
  CoverButton: FormCoverButtonElement,
  Divider: FormJson,
  Image: FormImageElement,
  List: FormListElement,
  Media: FormJson,
  Paper: FormJson,
  ReadTime: FormReadTimeElement,
  Video: FormVideoElement,
  Button: FormButtonElement,
  Index: FormIndexElement,
  // Popup: FormJson
  Popup: FormPopupElement
};

export const RenderElementForm: React.FC<OwnProps> = observer(({ data, presenter, forceJson }) => {

  let FormComponent: ElementForm<AnyElement> = Forms[data.component];

  if (forceJson || !FormComponent) {
    FormComponent = FormJson;
  }

  return <FormComponent element={data} onChange={presenter.updateElement} />;
});
