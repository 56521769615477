import * as React from "react";
import { InterfaceTools, InterfaceData, InterfaceActions } from "../view/layout/partials/default-interface-presenter";
import { DrawerMenu } from "../view/layout/drawers/menu";
import { DrawerNotifications } from "../view/layout/drawers/notifications";
import { DrawerContent } from "../view/layout/drawers/content";

import { Redirect } from "react-router";
import { route } from "./routes";
import { DrawerJsonEditor } from "../view/layout/drawers/json-editor";

export enum DrawerVariant {
  Overlay = "overlay",
  Pusher = "push",
}

export interface ToolConfig {
  title: string;
  width: number | string;
  shadow?: boolean;
  color?: string;
  variant?: DrawerVariant;
  background?: string;
  active: boolean;
  render(data: InterfaceData, actions: InterfaceActions): React.ReactNode;
}

export interface ToolRenderProps {
  data: InterfaceData;
  actions: InterfaceActions;
}

export const toolConfig: Record<InterfaceTools, ToolConfig> = {
  menu: {
    title: "Menu",
    width: 204,
    active: false,
    render: (data, actions) => {
      return React.createElement(DrawerMenu, { pages: data.pages });
    },
  },
  editor: {
    title: "",
    width: "calc(100% - 48px)",
    // shadow: true,
    background: "rgb(30,30,30)",
    variant: DrawerVariant.Overlay,
    color: "white",
    active: false,
    render: (data, actions) => {
      return React.createElement(DrawerJsonEditor, {
        currentJSON: data.currentJSON,
        onChange: actions.onJsonChange,
        onSave: actions.onJsonSave,
      });
    },
  },
  homepage: {
    title: "Homepage",
    width: 0,
    active: false,
    render: (data, actions) => {
      return React.createElement(Redirect, { to: route("app.homepage") });
    },
  },
  notifications: {
    title: "Notifications",
    width: 312,
    active: false,
    render: (data, actions) => {
      return React.createElement(DrawerNotifications);
    },
  },
  "page-info": {
    title: "Information",
    width: 312,
    active: false,
    shadow: true,
    render: () => {
      return React.createElement(DrawerContent);
    },
  },
  support: {
    title: "Pentair Support",
    width: 312,
    active: false,
    render: () => {
      return React.createElement(DrawerContent);
    },
  },
  "not-set": {
    title: "",
    width: 0,
    active: false,
    render: () => {
      return undefined;
    },
  },
};
