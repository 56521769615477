import * as React from "react";
import { observer } from "mobx-react";
import { withStyles, WithStyles, StyleRules, Theme } from "@material-ui/core";

import { LayoutEditorPresenter } from "../../../content/editors/layout-editor-presenter";
import { LayoutEditor } from "../../../content/editors/layout-editor";
import { Layout } from "react-grid-layout";
import { LayoutRenderer } from "@coderehab/greenzine-react-deprecated";
import { AnyElement } from "@coderehab/greenzeen-content";

interface OwnProps extends WithStyles<"root"> {
  layout: Layout[];
  elements: AnyElement[];
  preview?: boolean;
  onEditorReady(editor: LayoutEditorPresenter): void;
  onLayoutUpdated(layout: Layout[], elements: AnyElement[]): void;
}

const styles = (theme: Theme): StyleRules => ({
  root: {},
});

export const PageLayout = withStyles(styles)(
  observer(({ elements, layout, onEditorReady, preview, onLayoutUpdated }: OwnProps) => {
    return !preview ? (
      <LayoutEditor
        elements={elements}
        layout={layout}
        onReady={onEditorReady}
        onUpdate={onLayoutUpdated}
      />
    ) : (
      <LayoutRenderer elements={elements} layout={layout} />
    );
  })
);
