import * as React from "react";

export const DrawerContent = () => {
  return (
    <>
      Greenzeen is a product created by code.rehab <br />
      <br />
      Version 0.1
    </>
  );
};
