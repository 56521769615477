import * as React from "react";
import { DefaultStyleAttributeEditor } from "./default";
import { InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import {  observable } from "mobx";
import { observer } from "mobx-react";

@observer
export class BackgroundSizeStyleEditor extends DefaultStyleAttributeEditor {
  public defaultValue: string = "initial";
  @observable isCustom: boolean = false;
  
  public onSelect = (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
    if (e.target.value !== "custom") {
      this.isCustom = false
      this.updateValue(e.target.value);
    } else {
      this.isCustom = true
      this.updateValue("50% 50%")
    }
  };

  public render() {
    return (
      <>
        <InputLabel shrink id="select-backgroundrepeat-label">
         {this.property}
        </InputLabel>

        <Select
          labelId="select-backgroundrepeat-label"
          fullWidth
          value={this.isCustom ? "custom" : this.value?.toString() || "inherit" }
          onChange={this.onSelect}
        >
          <MenuItem value={"initial"}>initial</MenuItem>
          <MenuItem value={"inherit"}>inherit</MenuItem>
          <MenuItem value={"cover"}>cover</MenuItem>
          <MenuItem value={"contain"}>contain</MenuItem>
          <MenuItem value={"custom"}>custom</MenuItem>
        </Select>

        {this.isCustom && (
          <div style={{ padding: "24px 0 0 0" }}>
          <TextField
            fullWidth
            variant="standard"
            value={this.value}
            label={"Custom backgroundSize"}
            onChange={this.onChange}
            />
          </div>)
        }

      </>
    );
  }
}
