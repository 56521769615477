import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { EditionProvider } from "../../../application/data/edition/edition-provider";
import { Magazine } from "../../../application/data/magazine/magazine";
import { MagazineProvider } from "../../../application/data/magazine/magazine-provider";
import { route } from "../../../config/routes";
import { IPresenter } from "../../../helpers/with-presenter";
import { AuthPagePresenter } from "../_base-auth-page-presenter";
import { JSONInteractor } from "../../../application/business/interactor/json-interactor";
import { Edition } from "../../../application/data/edition/edition";
import { ArticleProvider } from "../../../application/data/article/article-provider";

export class ArticleIndexPresenter extends AuthPagePresenter implements IPresenter {
  @observable public collectionLoading: boolean = false;
  @observable public magazineID: string;
  @observable public editionID: string;
  @observable public magazine: Magazine;
  @observable public edition: Edition;
  @observable public loading: boolean;

  @computed get articleCollection() {
    if (this.editionID) {
      return this.edition && this.edition.articleCollection;
    }

    return this._articleProvider.collect();
  }

  @computed get articles() {
    return (this.articleCollection && this.articleCollection.items) || [];
  }

  constructor(
    protected _magazineProvider: MagazineProvider,
    protected _editionProvider: EditionProvider,
    protected _articleProvider: ArticleProvider,
    protected _JSONInteractor: JSONInteractor,
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps<{
      magazine?: string;
      edition?: string;
    }>
  ) {
    super(_userInteractor, _router);
    this.magazineID = this._router.match.params.magazine || "";
    this.editionID = this._router.match.params.edition || "";
  }

  public mount = async () => {
    this.loading = true;
    if (this.magazineID) {
      this.magazine = this._magazineProvider.get(this.magazineID) as Magazine;
    }

    await Promise.all([this.magazine.fetch()]);

    if (this.editionID) {
      const edition = this.magazine.editionCollection.find(this.editionID);
      if (edition) {
        this.edition = edition;
        this.edition.magazineId = this.magazineID;
        this._JSONInteractor.activeJSON = this.edition.serialize();
        this._JSONInteractor.onSaveListener(this.saveJson);
      }
    }
    this.loading = false;
  };

  public unmount = () => {
    this._JSONInteractor.activeJSON = {};
  };

  public selectArticle = (id: string) => {
    this.redirect(
      route("app.article.single", {
        magazine: this.magazineID,
        edition: this.editionID,
        article: id,
      })
    );
  };

  public createArticle = async () => {
    const article = this._articleProvider.create({ title: "new Article" });
    await article.save();
    if (this.edition) {
      this.edition.record.articles?.push(article.serialize());
      this._JSONInteractor.activeJSON = this.edition.serialize();
      await this.edition.save();
    } else {
      this.articleCollection.items.splice(0, 0, article);
    }
  };

  public deleteArticle = (id: string) => {};

  public resetForm = () => {};

  public saveJson = (data: any) => {
    if (this.edition) {
      this.edition.title = data.title;
      this.edition.save();
    }
  };
}
