import { Table, TableBody, TableCell, TableHead, TableRow, Button } from "@material-ui/core";
import { Article } from "@coderehab/greenzine-react-deprecated";
import React from "react";
import { mapEvent } from "../../../helpers/formatters";
import { IconButton } from "../../content/mui-extended/mui-icon-button";

interface OwnProps {
  articles: Article[];
  onRowSelect(id: string): void;
  onEdit(id: string): void;
  onDelete(id: string): void;
  onCreate(): void;
}

export const TableArticles = function({ articles, onEdit, onDelete, onCreate }: OwnProps) {
  return (
    <section>
      <Button variant={"contained"} color={"primary"} onClick={mapEvent(onCreate)}>
        Create article
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding={"checkbox"} children={""} />
            {/* <TableCell padding={"checkbox"} children={""} /> */}
            <TableCell children={"title"} />
            <TableCell children={"slug"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.map((article, i) => {
            return (
              <TableRow key={article.id}>
                <TableCell
                  padding={"checkbox"}
                  children={<IconButton onClick={mapEvent(onEdit, article.id)} icon="pencil-alt" />}
                />
                {/* <TableCell
                  padding={"checkbox"}
                  children={<IconButton onClick={mapEvent(article.delete)} icon="trash-alt" />}
                /> */}
                <TableCell children={i + 1 + ". " + article.title} />
                <TableCell children={article.slug} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
};
