import {
  Magazine,
  MagazineData,
  MagazineProvider,
  Collection,
} from "@coderehab/greenzine-react-deprecated";
import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { route } from "../../../config/routes";
import { IPresenter } from "../../../helpers/with-presenter";
import { AuthPagePresenter } from "../_base-auth-page-presenter";

export class MagazinePresenter extends AuthPagePresenter implements IPresenter {
  @observable public selectedMagazine: Magazine | undefined = undefined;

  @computed get loading() {
    return this.collection.loading;
  }

  @computed get collection(): Collection<Magazine> {
    return this._magazineProvider.collect();
  }

  @computed get magazines() {
    return this.collection.items;
  }

  constructor(
    protected _magazineProvider: MagazineProvider,
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps<{ design?: string }>
  ) {
    super(_userInteractor, _router);
  }

  public mount = async () => {
    await this.collection.fetch();
  };

  public unmount = () => {
    // Nope
  };

  public selectMagazine = (id: string) => {
    this.redirect(route("app.edition.overview", { magazine: id }));
  };

  public configureMagazine = (id: string) => {
    this.redirect(route("app.magazine.config", { magazine: id }));
  };

  public createMagazine = (data: MagazineData) => {};

  public deleteMagazine = (id: string) => {};

  public resetForm = () => {};
}
