import * as React from "react";
import { DefaultStyleAttributeEditor } from "./default";
import { Slider, Typography, InputLabel } from "@material-ui/core";
import { observer } from "mobx-react";

@observer
export class MaxWidthPositionStyleEditor extends DefaultStyleAttributeEditor {
  public defaultValue: string | number = "none";

  public onSliderChange = (e: any, value: number | number[]) => {
    this._value = value[0] || value;
    this.updateValue(this._value);
  };

  public render() {
    return (
      <>
        <InputLabel shrink id="select-maxiwdth-label">
          {this.property}
        </InputLabel>
        <Typography>{this.value}px</Typography>
        <Slider
          defaultValue={typeof this.value === "number" ? this.value : -1}
          step={50}
          marks
          min={0}
          max={1920}
          valueLabelDisplay="auto"
          onChangeCommitted={this.onSliderChange}
        />
      </>
    );
  }
}
