// import { Design } from "../design/design";
import { Auth } from "aws-amplify";
import { observable } from "mobx";
import { User } from "./user";
import { UserProvider } from "./user-provider";

export interface UserInteractor {
  currentUser: User;
  logout: () => void;
}

export class DefaultUserInteractor implements UserInteractor {
  @observable public _currentUser: User | null = null;

  constructor(protected userProvider: UserProvider) {
    //
  }

  public get currentUser(): User {
    if (!this._currentUser) {
      throw new Error("User not set");
    }
    return this._currentUser;
  }

  public set currentUser(user: User) {
    this._currentUser = user;
  }

  public logout = () => {
    this._currentUser = null;
    Auth.signOut();
    window.location.reload();
  };
}
