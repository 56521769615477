import { IconName } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Theme, Typography } from "@material-ui/core";
import { StyleRules, withStyles, WithStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { CanView } from "../content/components/can-view/can-view";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { typographyVariants } from "../theme/config";
import { AuthPagePresenter } from "./_base-auth-page-presenter";

type OwnProps = RouteComponentProps &
  WithStyles<"root" | "layout" | "content" | "intro" | "button" | "buttonLabel" | "buttonIcon">;

interface ButtonOptions {
  label: string;
  icon: IconName;
  href: string;
  disabled?: boolean;
  roles: string[];
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    backgroundColor: theme.palette.white.main,
  },

  content: {
    textAlign: "center",
    maxWidth: 900,
  },

  intro: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    fontSize: "1rem",
    "&:before": {
      top: "85%",
    },
  },

  buttonLabel: {
    flexDirection: "column",
  },

  buttonIcon: {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
});

const HomeComponent = withStyles(styles)(
  observer(({ classes, presenter }: OwnProps & PresenterProps<AuthPagePresenter>) => {
    const options: ButtonOptions[] = [
      {
        label: "Bekijk alle magazines",
        icon: "digital-tachograph",
        href: "/magazines",
        roles: ["public"],
      },
      // {
      //   label: "Bekijk poll 51 resultaten",
      //   icon: "digital-tachograph",
      //   href: "/polls/poll-bondig-51",
      //   roles: ["public"],
      // },
      // {
      //   label: "Bekijk poll 52 resultaten",
      //   icon: "digital-tachograph",
      //   href: "/polls/poll-bondig-52",
      //   roles: ["public"],
      // },
      // {
      //   label: "Bekijk poll 53 resultaten",
      //   icon: "digital-tachograph",
      //   href: "/polls/poll-bondig-53",
      //   roles: ["public"],
      // },
      // {
      //   label: "Bekijk poll 54 resultaten",
      //   icon: "digital-tachograph",
      //   href: "/polls/poll-bondig-54",
      //   roles: ["public"],
      // },
    ];

    const { user } = presenter;

    if (!user) {
      return <Typography>loading user</Typography>;
    }

    return (
      <Layout noHeader classes={{ root: classes.layout }}>
        <div className={classes.root}>
          {console.log(user)}

          <div className={classes.content}>
            <FontAwesomeIcon icon={["fas", "newspaper"]} size={"6x"} color={"#000"} />

            <div className={classes.intro}>
              <Typography gutterBottom variant={typographyVariants.h1} color={"primary"}>
                Hi {user.attributes.email}
              </Typography>
            </div>

            <Typography paragraph variant={typographyVariants.h3}>
              What would you like to do?{" "}
            </Typography>

            <Grid container spacing={1} direction={"row"} justify={"center"} alignItems="center">
              {options.map((cfg, index) => (
                <CanView roles={cfg.roles}>
                  <Grid key={index} item xs={6} sm={4} md={3} style={{ maxWidth: 170 }}>
                    <Button
                      fullWidth
                      classes={{
                        root: classes.button,
                        label: classes.buttonLabel,
                        startIcon: classes.buttonIcon,
                      }}
                      variant={"outlined"}
                      color={"secondary"}
                      component={Link}
                      to={cfg.href}
                      disabled={cfg.disabled}
                    >
                      {cfg.label}
                    </Button>
                  </Grid>
                </CanView>
              ))}
            </Grid>
          </div>
        </div>
      </Layout>
    );
  })
);

export const PageHome = withRouter(
  withPresenter<AuthPagePresenter, RouteComponentProps>(
    (props, { interactor }) => new AuthPagePresenter(interactor.user, props),
    HomeComponent
  )
);
