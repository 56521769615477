import * as React from "react";
import {
  Drawer,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Button,
  withStyles,
  StyleRules,
  Theme,
  WithStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { mapEvent, nodeValue } from "../../../../helpers/formatters";
import { StyleEditor } from "../../../content/editors/style-editor";
import LoadingIcon from "../../../content/components/loading";
import { observer } from "mobx-react";
import { ArticlePagePresenter } from "../article-page-presenter";

interface OwnProps extends WithStyles<"field"> {
  presenter: ArticlePagePresenter;
}

const styles = (theme: Theme): StyleRules => ({
  field: {
    margin: theme.spacing(1, 0, 1, 0),
  },
});

export const PageSettings = withStyles(styles)(
  observer(({ presenter, classes }: OwnProps) => {
    const { page, isPublishing, isEditing, stopEditing, publishPage, updateStyles } = presenter;

    return (
      <Drawer open={isEditing} onClose={stopEditing} anchor="right">
        <div style={{ padding: 24 }}>
          <Typography variant="h3" paragraph>
            Page configuration
          </Typography>

          <ExpansionPanel defaultExpanded style={{ maxWidth: 400 }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-general-content"
              id="panel-general-header"
              style={{ padding: 0 }}
            >
              <Typography variant="h4">General</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails style={{ flexDirection: "column", padding: 0 }}>
              <TextField
                className={classes.field}
                variant="outlined"
                value={page.title}
                label={"Title"}
                fullWidth
                onChange={mapEvent(page.updateProperty, "title", nodeValue)}
              />

              <TextField
                variant="outlined"
                value={page.slug}
                label={"Slug"}
                fullWidth
                onChange={mapEvent(page.updateProperty, "slug", nodeValue)}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ maxWidth: 400 }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-styling-content"
              id="panel-styling-header"
              style={{ padding: 0 }}
            >
              <Typography variant="h4">Styling</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails style={{ flexDirection: "column", padding: 0 }}>
              <StyleEditor
                style={page.styleObj}
                onChange={updateStyles}
                selection={["maxWidth", "padding", "color", "backgroundColor", "backgroundImage","background"]}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Button
            onClick={publishPage}
            color={"primary"}
            variant={"contained"}
            endIcon={isPublishing && <LoadingIcon />}
            style={{ marginTop: 20 }}
          >
            Publish page
          </Button>
        </div>
      </Drawer>
    );
  })
);
