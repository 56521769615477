import React from "react";

import {} from "@fortawesome/fontawesome-svg-core";
import {} from "@fortawesome/react-fontawesome";
import { makeStyles, Theme, Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import { Image } from "../../application/data/image/image";
import { mapEvent } from "../../helpers/formatters";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  media: {
    height: 200,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  selected: {
    backgroundColor: "#eee",
  },
}));

interface OwnProps {
  image: Image;
  selected: boolean;
  onClick(image: Image): void;
}

export default function ImageItem({ image, selected, onClick }: OwnProps) {
  const classes = useStyles();
  // console.log(data);

  return (
    <Card className={classNames(classes.root, selected && classes.selected)} onClick={mapEvent(onClick, image)}>
      <CardMedia className={classes.media} image={window.location.protocol + "//" + image.url} title={image.name} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {image.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {image.path}
          {"https://" + image.url}
          Aspect ratio: {image.aspect}
        </Typography>
      </CardContent>
    </Card>
  );

  // <img src={"://" + data.url} title={data.name} className={classes.image} />;
}
