import * as React from "react";
import { Button, StyleRules, Theme, withStyles, WithStyles, Typography, Tooltip } from "@material-ui/core";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { ArticlePagePresenter } from "../article-page-presenter";
import { mapEvent } from "../../../../helpers/formatters";
import LoadingIcon from "../../../content/components/loading";

interface OwnProps extends WithStyles<"root"> {
  presenter: ArticlePagePresenter;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    padding: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const PageHeader = withStyles(styles)(
  observer(({ presenter, classes }: OwnProps) => {
    const {
      page,
      onCreateElement,
      startEditing,
      publishPage,
      rulerActive,
      isPublishing,
      togglePreview,
      setBreakpoint,
      getBreakpoint,
      toggleRuler,
    } = presenter;

    return (
      <div className={classes.root}>
        <div>
          <Typography variant={"h3"}>Page: {page.title}</Typography>
          <Typography>
            <small>{page.id}</small>
          </Typography>
        </div>
        <div>
          <Tooltip title="Ruler" arrow placement="top">
            <Button
              style={{ margin: "0px 1px" }}
              onClick={mapEvent(toggleRuler)}
              color={rulerActive ? "primary" : "secondary"}
              variant={"contained"}
              title={"Show vertical ruler"}
            >
              <FontAwesomeIcon icon="ruler-vertical" size={"lg"} />
            </Button>
          </Tooltip>

          <Tooltip title="Desktop" arrow placement="top">
            <Button
              style={{ margin: "0px 1px" }}
              onClick={mapEvent(setBreakpoint, "lg")}
              color={getBreakpoint() === "lg" ? "primary" : "secondary"}
              variant={"contained"}
            >
              <FontAwesomeIcon icon="desktop" size={"lg"} />
            </Button>
          </Tooltip>
          <Tooltip title="Tablet" arrow placement="top">
            <Button
              style={{ margin: "0px 1px" }}
              onClick={mapEvent(setBreakpoint, "md")}
              color={getBreakpoint() === "md" ? "primary" : "secondary"}
              variant={"contained"}
            >
              <FontAwesomeIcon icon="tablet" size={"lg"} />
            </Button>
          </Tooltip>

          <Tooltip title="Mobile" arrow placement="top">
            <Button
              style={{ margin: "0px 1px" }}
              onClick={mapEvent(setBreakpoint, "sm")}
              color={getBreakpoint() === "sm" ? "primary" : "secondary"}
              variant={"contained"}
            >
              <FontAwesomeIcon icon="mobile" size={"lg"} />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Add element" arrow placement="top">
            <Button
              style={{ margin: "0px 1px" }}
              color={"secondary"}
              variant={"contained"}
              onClick={onCreateElement}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            ></Button>
          </Tooltip>

          <Tooltip title="Preview" arrow placement="top">
            <Button style={{ margin: "0px 1px" }} onClick={togglePreview} color={"secondary"} variant={"contained"}>
              <FontAwesomeIcon icon="eye" size={"lg"} />
            </Button>
          </Tooltip>
          <Tooltip title="Settings" arrow placement="top">
            <Button style={{ margin: "0px 1px" }} onClick={startEditing} color={"secondary"} variant={"contained"}>
              <FontAwesomeIcon icon="cog" size={"lg"} />
            </Button>
          </Tooltip>
          <Tooltip title="Save" arrow placement="top">
            <Button
              style={{ margin: "0px 1px" }}
              color={"primary"}
              variant={"contained"}
              onClick={publishPage}
              endIcon={isPublishing && <LoadingIcon />}
            >
              <FontAwesomeIcon icon="save" size={"lg"} />
            </Button>
          </Tooltip>
         {/* <Tooltip title="Delete" arrow placement="top">
             <Button
              color={"primary"}
              variant={"contained"}
              onClick={mapEvent(onDelete, page)}
              style={{ backgroundColor: "#bb1509" }}
            >
              <FontAwesomeIcon icon="trash-alt" size={"lg"} />
            </Button> 
          </Tooltip>*/}
        </div>
      </div>
    );
  })
);
