import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { Application } from "../../application";
import { Edition } from "../edition/edition";
import { computed } from "mobx";

export interface Magazine extends Greenzine.Magazine {
  // articles: Article[];
  editionCollection: Greenzine.Collection<Edition>;
}

export class MagazineModel extends Greenzine.MagazineModel implements Magazine {
  public get editionCollection(): Greenzine.Collection<Edition> {
    const editionProvider = Application.business.provider.edition;
    return editionProvider
      .collect(this.record.editions)
      .where("magazine", this.id) as Greenzine.Collection<Edition>;
  }

  @computed public get editions(): Edition[] {
    return this.editionCollection.items.map((edition) => {
      edition.magazineId = this.id;
      return edition;
    });
  }

  public serialize = () => {
    const result = { ...this._values };
    result.editions =
      (result.editions && (result.editions.map((edition) => edition.id) as any)) || [];

    delete result.about;
    return result;
  };
}
