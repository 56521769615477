import { InterfaceTools } from "../../../view/layout/partials/default-interface-presenter";
import { ToolConfig, toolConfig } from "../../../config/interface-tools";
import { computed, observable } from "mobx";
import { JSONInteractor } from "../interactor/json-interactor";
import { getTheme } from "@coderehab/greenzeen-themes";

export interface InterfaceInteractor {
  activeTool: InterfaceTools;
  config: Record<InterfaceTools, ToolConfig>;
  activeToolConfig: ToolConfig;
  theme?: string;
  setJSON(data: Record<string, any>): void;
}

export class DefaultInterfaceInteractor implements InterfaceInteractor {
  public config = toolConfig;

  @observable public activeTool: InterfaceTools = InterfaceTools.JSONeditor;
  @observable public theme?: string = "default";

  constructor(protected _JSONinteractor: JSONInteractor) {}

  @computed
  public get activeToolConfig() {
    return this.config[this.activeTool];
  }

  @computed
  public get activeTheme() {
    return getTheme(this.theme || "default");
  }

  public setJSON(data: Record<string, any>) {
    this._JSONinteractor.activeJSON = data;
    this.activeTool = InterfaceTools.JSONeditor;
  }
}
