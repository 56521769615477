import { observable } from "mobx";
import { v4 as uuid } from "uuid";
import { AnyElement, createElement, ElementType } from "@coderehab/greenzeen-content";

interface CallbackHandlers {
  onCancel?: (data: AnyElement) => void;
  onChange?: (data: AnyElement) => void;
  onUpdate?: (data: AnyElement) => void;
}

export interface ElementInteractor {
  active: boolean;
  data: AnyElement | null;
  callback: CallbackHandlers;
  open(data: AnyElement, callbacks: CallbackHandlers): void;
  cancel(): void;
  update(): void;
  change(): void;
  createElement(type?: ElementType, config?: any): AnyElement;
}

export class DefaultElementInteractor implements ElementInteractor {
  @observable public active: boolean = false;
  @observable public data: AnyElement | null = null;

  public callback: CallbackHandlers = {};

  public open = (data: AnyElement, callback: CallbackHandlers) => {
    this.reset();
    this.callback = callback;
    this.data = data;
    this.active = true;
  };

  public cancel = () => {
    this._onChange();
    this._onCancel();
    this._close();
  };

  public update = () => {
    this._onChange();
    this._onUpdate();
    this._close();
  };

  public change = () => {
    this._onChange();
  };

  protected reset = () => {
    this.callback = {};
    this.data = null;
  };

  protected _close = () => {
    this.reset();
    this.active = false;
  };

  private _onChange = () => {
    
    if (this.data) {
      if (this.callback.onChange && this.data) {
        this.callback.onChange(this.data);
      }
    }
  };

  private _onCancel = () => {
    if (this.callback.onCancel && this.data) {
      this.callback.onCancel(this.data);
    }
  };

  private _onUpdate = () => {
    if (this.callback.onUpdate && this.data) {
      this.callback.onUpdate(this.data);
    }
  };

  public createElement = (type: ElementType = ElementType.Typography, config?: any): AnyElement => {
    return createElement({
      id: uuid(),
      component: type,
      config: config || ({ children: "Your content", gutterBottom: false, paragraph: true } as any),
    });
  };
}
