import * as React from "react";

import { StyleRules, Theme, withStyles, WithStyles } from "@material-ui/core";
import { ArticlePage } from "../../article-page/article-page";
import { observer } from "mobx-react";
import { ArticleSinglePresenter } from "../article-single-presenter";

interface OwnProps extends WithStyles<"root" | "pageRoot"> {
  presenter: ArticleSinglePresenter;
  theme: string;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#eee",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, -2, 0, -2),
  },
  pageRoot: {
    width: "100%",
    position: "relative",
    backgroundColor: "#fff",
    margin: theme.spacing(1, 0),
    minHeight: "calc(" + (9 / 16) * 100 + "vw - 120px)",
  },
});

export const ArticlePages = withStyles(styles)(
  observer(({ presenter, classes }: OwnProps) => {
    const { pages, onPageDelete, theme } = presenter;

    return (
      <section className={classes.root}>
        {pages.map((page) => {
          return (
            <ArticlePage
              theme={theme || "default"}
              key={page.id}
              page={page}
              classes={{
                root: classes.pageRoot,
              }}
              onDelete={onPageDelete}
            />
          );
        })}
      </section>
    );
  })
);
