import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { CanView } from "../../content/components/can-view/can-view";
import Widget from "../../content/components/widget/widget";
import { DefaultLayout as Layout } from "../../layout/layout-default";
import { ArticleIndexPresenter } from "./article-index-presenter";
import { TableArticles } from "./article-table";
import LoadingIcon from "../../content/components/loading";

type OwnProps = RouteComponentProps;

const UnauthorizedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        opacity: 0.3,
      }}
    >
      <Typography variant={"h1"} style={{ fontSize: "calc(2em + 1vw)" }}>
        Unauthorized
      </Typography>
      <Typography variant={"h5"}>you are not allowed to view this page</Typography>
    </div>
  );
};

@observer
class ArticleOverviewComponent extends React.Component<
  OwnProps & PresenterProps<ArticleIndexPresenter>
> {
  public render() {
    const {
      articles,
      selectArticle,
      createArticle,
      edition,
      loading,
    } = this.props.presenter;

    return (
      <Layout titlePrefix={(edition && "Edition: " + edition.title) || "All"} title={"articles"}>
        {loading ? (
          <LoadingIcon></LoadingIcon>
        ) : (
          <CanView notAuthorized={<UnauthorizedPage />}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Widget title="Article overview">
                  <TableArticles
                    onCreate={createArticle}
                    onRowSelect={() => {}}
                    onEdit={selectArticle}
                    onDelete={() => {}}
                    articles={articles}
                  />
                </Widget>
              </Grid>
            </Grid>
          </CanView>
        )}
      </Layout>
    );
  }
}

export const PageArticleOverview = withRouter(
  withPresenter<ArticleIndexPresenter, RouteComponentProps>(
    (props, { interactor, provider }) =>
      new ArticleIndexPresenter(
        provider.magazine,
        provider.edition,
        provider.article,
        interactor.json,
        interactor.user,
        props
      ),
    ArticleOverviewComponent
  )
);
