export const ImageFragment = `
  id
  name
  description
  url
  path
  aspect
  updated_at
  created_at
`;
