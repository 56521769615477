import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { computed } from "mobx";

export interface Poll extends Greenzine.Poll {
  groupedSubmissions: any;
}

export class PollModel extends Greenzine.PollModel implements Poll {
  @computed public get groupedSubmissions() {
    const result = this.submissions.reduce((result: any, submission) => {
      result[submission.questionID] = result[submission.questionID] || [];
      result[submission.questionID].push(submission.answers);
      return result;
    }, {});

    return result;
  }
}
