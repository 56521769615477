import { ImageFragment } from "./fragments";

export const Image = `
  query Image($id:ID!) {
    Image(id:$id){
      ${ImageFragment}
    }
  }
`;

export const ImageCollection = `
  query ImageCollection {
    ImageCollection {
      ${ImageFragment}
    }
  }
`;
