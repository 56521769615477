import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { data } from "@coderehab/greenzine-react-deprecated";
import { computed } from "mobx";

export interface ImageRecord extends Greenzine.RecordData {
  id: string;
  name: string;
  description: string;
  url: string;
  srcset?: any;
  path: string;
  aspect?: string;
  updated_at: string;
  created_at: string;
}

export interface Image extends Greenzine.Model<ImageRecord> {
  //
}

export class ImageModel extends Greenzine.GraphQLBase<ImageRecord> implements Image {
  @data public description: string;
  @data public path: string;
  // @data public aspect: string;
  @data public updated_at: string;
  @data public created_at: string;

  @computed public get name() {
    return this.record.name || "not-set";
  }

  @computed public get url() {
    return this.record.url || "not-set";
  }

  @computed public get aspect() {
    return this.record.aspect || "not-set";
  }
}
