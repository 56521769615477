import * as React from "react";
import { DefaultStyleAttributeEditor } from "./default";
import { StyleEditor } from "../style-editor";
import { observer } from "mobx-react";
import { FileSelector } from "@coderehab/greenzeen-media-library";
import { FormLabel } from "@material-ui/core";

@observer
export class BackgroundImageStyleEditor extends DefaultStyleAttributeEditor {
  public defaultValue = "";

  public selectImage = () => {
    this.presenter.selectImage(this.value as string, {
      onUpdate: this.onImageSelected,
    });
  };

  public onImageSelected = (imageUrl: string) => {
    this.updateValue("url('" + imageUrl + "')");
  };

  onStyleUpdate = (style: React.CSSProperties) => {
    this.props.onChange(style);
  };

  public render() {
    return (
      <>
        <FormLabel style={{ fontSize: 12 }}>{this.property}</FormLabel>
        <FileSelector
          value={{ href: (this.value || "").replace("url('", "").replace("')", "") }}
          onChange={(obj) => obj && this.onImageSelected(obj.href)}
        />

        {this.value && (
          <div style={{ padding: "24px 0 0 0" }}>
            <StyleEditor
              selection={["backgroundPosition", "backgroundRepeat", "backgroundSize"]}
              style={this.style}
              onChange={this.onStyleUpdate}
            />
          </div>
        )}
      </>
    );
  }
}
