import {
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
  withAuthenticator,
} from "aws-amplify-react";
import { observer } from "mobx-react";
import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import { PresenterProps, withPresenter } from "../helpers/with-presenter";
import { AuthPresenter } from "./auth-presenter";
import LoadingIcon from "./content/components/loading";
// import { FederatedSignIn } from "./auth/federated-signin";
import AuthTheme from "./theme/auth";
import { DefaultInterface } from "./layout/partials/default-interface";

let presenter: AuthPresenter | undefined = undefined;

const AuthRouteComponent = withPresenter<AuthPresenter, RouteProps>(
  ({}, { interactor, provider }) => {
    if (!presenter) {
      presenter = new AuthPresenter(interactor.user, provider.user);
    }

    return presenter;
  },
  observer((props: RouteProps & PresenterProps<AuthPresenter>) => {
    if (props.presenter.loading) {
      return <LoadingIcon />;
    }

    return (
      <div>
        <Route {...props} />
        <DefaultInterface />
      </div>
    );
  })
);

export const AuthRoute = withAuthenticator(
  AuthRouteComponent,
  false,
  [<SignIn />, <ConfirmSignIn />, <RequireNewPassword />, <ForgotPassword />, <VerifyContact />],
  null,
  AuthTheme
);
