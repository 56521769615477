import { History } from "history";
import { observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { IPresenter } from "../../helpers/with-presenter";

export class PagePresenter implements IPresenter {
  @observable public loading: boolean = false;
  protected history: History;

  constructor(protected _router: RouteComponentProps) {
    this.history = _router.history;
  }

  public mount = () => {};
  public unmount = () => {};

  public back = (e: any) => {
    e.preventDefault();
    this._router.history.goBack();
  };

  public redirect = (path: string) => {
    this._router.history.push(path);
  };
}
