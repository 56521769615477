import { withStyles, WithStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
// import { DrawerWrap } from "../drawers/wrap";
import { InterfacePresenter } from "./default-interface-presenter";
import { ToolBar } from "./toolbar";
import TopBar from "./topbar";

import { DataEditor } from "../../pages/element/data-editor";
import { ImageSelector } from "../../interface/image-selector";

interface OwnProps {}

const Component = withStyles({
  root: {
    position: "fixed",
    display: "flex",
    alignItems: "flex-start",
    left: 0,
    right: 0,
    top: 0,
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "auto",
    },
  },
  drawerWrap: {
    background: "#fff",
  },
})(
  observer(({ presenter, classes }: OwnProps & WithStyles & PresenterProps<InterfacePresenter>) => {
    // console.log("render interface");
    return (
      <div className={classes.root}>
        <ToolBar onToolSelect={presenter.toggleTool} active={presenter.activeTool} />
        <div className={classes.drawerWrap}></div>
        <TopBar user={presenter.user} onLogout={presenter.logout} />
        <DataEditor />
        <ImageSelector />
      </div>
    );
  })
);

export const DefaultInterface = withPresenter<InterfacePresenter, OwnProps>(
  (_props, { interactor }) =>
    new InterfacePresenter(interactor.user, interactor.interface, interactor.json),
  Component
);
