import * as React from "react";
import {
  withStyles,
  Theme,
  WithStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  StyleRules,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { ImageSelectorPresenter } from "./image-selector-presenter";
import ImageItem from "./image";

interface OwnProps extends WithStyles<"root" | "imageContainer" | "image" | "gridItem" | "paperContainer"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    // display: "flex",
  },
  imageContainer: {
    // border: "1px solid #ccc",
    // width: 240,
    // padding: 20,
  },
  image: {
    // maxWidth: "100%",
  },
  gridItem: {
    maxWidth: 320,
  },
  paperContainer: {
    top: theme.spacing(10),
    bottom: theme.spacing(10),
    left: theme.spacing(10),
    right: theme.spacing(10),
    position: "fixed",
    maxWidth: "unset",
    maxHeight: "unset",
  },
});

const Component = observer(({ presenter, classes }: OwnProps & PresenterProps<ImageSelectorPresenter>) => {
  const { collection, active, close, select, selectedImage, onImageClick } = presenter;

  if (collection.loading) {
    return <div>Loading images</div>;
  }

  return (
    <Dialog classes={{ paper: classes.paperContainer }} open={active} onClose={close}>
      <DialogTitle id="alert-dialog-title">{"Image Library"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {collection.items.map((image, key: number) => {
            return (
              <>
                {/* <img
                  key={key}
                  className={"image-" + key.toString()}
                  src={window.location.protocol + "//" + image.url}
                  onLoad={function(ok: any) {
                    let img = document.querySelector(".image-" + key.toString());
                    if (img) {
                      const aspect = img.clientWidth / img.clientHeight;
                      updateAspectRatio(image, aspect);
                    }
                  }}
                /> */}
                <Grid item className={classes.gridItem} xs={12}>
                  <ImageItem
                    onClick={onImageClick}
                    selected={window.location.protocol + "//" + image.url === selectedImage}
                    image={image}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={select} color="primary" autoFocus>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const ImageSelector = withStyles(styles)(
  withPresenter<ImageSelectorPresenter, OwnProps>(
    ({}, { interactor, provider }) =>
      new ImageSelectorPresenter(interactor.interface, provider.image, interactor.image),
    Component
  )
);
