import { ArticleProvider, MagazineProvider, Magazine } from "@coderehab/greenzine-react-deprecated";
import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { Article } from "../../../application/data/article/article";
import { IPresenter } from "../../../helpers/with-presenter";
import { AuthPagePresenter } from "../_base-auth-page-presenter";
import { JSONInteractor } from "../../../application/business/interactor/json-interactor";
import { Page } from "../../../application/data/page/page";
import { PageProvider } from "../../../application/data/page/page-provider";
import { InterfaceInteractor } from "../../../application/business/interactor/interface-interactor";
import { MediaLibrary } from "@coderehab/greenzeen-media-library";

export class ArticleSinglePresenter extends AuthPagePresenter implements IPresenter {
  @observable public loading: boolean = false;
  @observable public publishing: boolean = false;
  @observable public articleEditing: boolean = false;
  @observable public _pages: Page[] = [];

  @computed get pages(): Page[] {
    return (this.article.pageOrder || [])
      .map((id) => this._pages.find((p) => p.id === id))
      .filter((p) => p) as Page[];
  }

  public articleId: string;
  public magazineID: string;
  public article: Article;
  public magazine: Magazine;

  constructor(
    protected _articleProvider: ArticleProvider,
    protected _magazineProvider: MagazineProvider,
    protected _pageProvider: PageProvider,
    protected _JSONInteractor: JSONInteractor,
    protected _userInteractor: UserInteractor,
    protected _interfaceInteractor: InterfaceInteractor,
    protected _router: RouteComponentProps<{
      article?: string;
      magazine?: string;
    }>
  ) {
    super(_userInteractor, _router);

    this.articleId = this._router.match.params.article || "";
    this.article = this._articleProvider.get(this.articleId);

    this.magazineID = this._router.match.params.magazine || "";
    this.magazine = this._magazineProvider.get(this.magazineID);
  }

  public get theme() {
    return this._interfaceInteractor.theme;
  }

  public mount = async () => {
    this.loading = true;
    await this.article.fetch();
    await this.magazine.fetch();

    MediaLibrary.configure({ tenant: this.magazine.config.tenant });

    this._pages = this.article.pages as Page[];

    if (!this.article.pageOrder || !this.article.pageOrder.length) {
      this.article.pageOrder = this._pages.map((p) => p.id);
    }

    this._JSONInteractor.activeJSON = this.article.serialize();
    this._JSONInteractor.onSaveListener(this.saveJson);

    this._interfaceInteractor.theme = this.magazine.config.theme || "default";
    this.loading = false;
  };

  public unmount = () => {
    this._JSONInteractor.activeJSON = {};
  };

  public deleteArticle = (id: string) => {};
  public resetForm = () => {};

  public saveJson = (data: any) => {
    this.article.changes = data;
    this.publishArticle();
  };

  public startEditing = () => {
    this.articleEditing = true;
  };

  public stopEditing = () => {
    this.articleEditing = false;
  };

  public onSortEnd = ({ oldIndex, newIndex }: any) => {
    const page = this.article.pageOrder!.splice(oldIndex, 1)[0];
    this.article.pageOrder!.splice(newIndex, 0, page);
  };

  public updateStyles = (style: React.CSSProperties) => {
    this.article.style = JSON.stringify(style);
  };

  public publishArticle = async () => {
    this.publishing = true;
    await this.article.save();
    this.publishing = false;
    this.stopEditing();
  };

  public createPage = async () => {
    const page = this._pageProvider.create({});
    page.articleId = this.article.id;
    page.article = this.article;
    await page.save();

    this._pages.splice(0, 0, page as Page);
    this.article.pageOrder = this._pages.map((p) => p.id);
    await this.article.save();
  };

  public onPageDelete = (id: string) => {
    const index = this._pages.findIndex((p) => p.id === id);
    this._pages.splice(index, 1);
    this.article.pageOrder = this._pages.map((p) => p.id);
    this.publishArticle();
  };
}
