import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { CanView } from "../../content/components/can-view/can-view";
import { FormCreateUser } from "../../content/components/form-user";
import { TableUsers } from "../../content/components/table-users";
import Widget from "../../content/components/widget/widget";
import { DefaultLayout as Layout } from "../../layout/layout-default";
import { UserPresenter } from "./user-presenter";

type OwnProps = RouteComponentProps;

const UnauthorizedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        opacity: 0.3
      }}
    >
      <Typography variant={"h1"} style={{ fontSize: "calc(2em + 1vw)" }}>
        Unauthorized
      </Typography>
      <Typography variant={"h5"}>
        you are not allowed to view this page
      </Typography>
    </div>
  );
};

@observer
class userOverviewComponent extends React.Component<
  OwnProps & PresenterProps<UserPresenter>
> {
  public render() {
    const {
      allUsers,
      user,
      createUser,
      createValues,
      selectedUser,
      selectUser,
      updateUser,
      resetForm
    } = this.props.presenter;

    if (!user) {
      return "loading user";
    }

    return (
      <Layout titlePrefix={"Greenzine"} title={"Users"} subTitle={"overview"}>
        <CanView notAuthorized={<UnauthorizedPage />}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8} lg={9}>
              <Widget title="User overview">
                <TableUsers
                  onCreate={() => {}}
                  onRowSelect={() => {}}
                  onEdit={selectUser}
                  onDelete={() => {}}
                  users={allUsers}
                />
              </Widget>
            </Grid>

            <Grid item xs={"auto"} md={4} lg={3}>
              <Widget title={selectedUser ? "Update user" : "Create user"}>
                <FormCreateUser
                  isNew={selectedUser ? false : true}
                  user={selectedUser ? selectedUser : createValues}
                  onCreate={createUser}
                  onUpdate={updateUser}
                  onCancel={resetForm}
                />
              </Widget>
            </Grid>
          </Grid>
        </CanView>
      </Layout>
    );
  }
}

export const PageUserOverview = withRouter(
  withPresenter<UserPresenter, RouteComponentProps>(
    (props, { interactor, provider }) =>
      new UserPresenter(interactor.user, provider.user, props),
    userOverviewComponent
  )
);
