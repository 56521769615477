import * as React from "react";
import {
  Drawer,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SortableList } from "../../../content/components/sortable";
import { mapEvent, nodeValue } from "../../../../helpers/formatters";
import { ArticleSinglePresenter } from "../article-single-presenter";
import LoadingIcon from "../../../content/components/loading";
import { observer } from "mobx-react";
import { StyleEditor } from "../../../content/editors/style-editor";
import { FileSelector } from "@coderehab/greenzeen-media-library";

interface OwnProps {
  presenter: ArticleSinglePresenter;
}

export const ArticleSettings = observer(({ presenter }: OwnProps) => {
  const {
    article,
    pages,
    onSortEnd,
    articleEditing,
    stopEditing,
    publishArticle,
    publishing,
    updateStyles,
  } = presenter;

  return (
    <Drawer open={articleEditing} onClose={stopEditing}>
      <div style={{ padding: 24 }}>
        <Typography variant="h3" paragraph>
          Article configuration
        </Typography>

        {/* GENERAL */}
        <ExpansionPanel style={{ maxWidth: 400 }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-general-content"
            id="panel-general-header"
            style={{ padding: 0 }}
          >
            <Typography variant="h4">General</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ flexDirection: "column", padding: 0 }}>
            <div style={{ margin: "12px 0" }}>
              <TextField
                fullWidth
                variant="outlined"
                disabled={true}
                value={article.id}
                label={"ID"}
                onChange={mapEvent(article.updateProperty, "id", nodeValue)}
              ></TextField>
            </div>
            <div style={{ margin: "12px 0" }}>
              <TextField
                fullWidth
                variant="outlined"
                value={article.title}
                label={"Title"}
                onChange={mapEvent(article.updateProperty, "title", nodeValue)}
              ></TextField>
            </div>
            <div style={{ margin: "12px 0" }}>
              <TextField
                fullWidth
                variant="outlined"
                value={article.slug}
                label={"Slug"}
                onChange={mapEvent(article.updateProperty, "slug", nodeValue)}
              ></TextField>
            </div>

            <div style={{ margin: "12px 0" }}>
              {/* <TextField
                variant="outlined"
                value={article.image}
                label={"Cover image"}
                style={{ width: 300 }}
                onChange={mapEvent(article.updateProperty, "image", nodeValue)}
              ></TextField> */}

              <FileSelector
                value={{ href: article.image }}
                onChange={(obj) => obj && article.updateProperty("image", obj.href)}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* STYLING */}
        <ExpansionPanel style={{ maxWidth: 400 }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-general-content"
            id="panel-general-header"
            style={{ padding: 0 }}
          >
            <Typography variant="h4">Styling</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ flexDirection: "column", padding: 0 }}>
            <StyleEditor
              style={article.styleObj}
              onChange={updateStyles}
              selection={["maxWidth", "padding", "color", "backgroundColor", "backgroundImage"]}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel style={{ maxWidth: 400 }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-general-content"
            id="panel-general-header"
            style={{ padding: 0 }}
          >
            <Typography variant="h4">Order Pages</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ flexDirection: "column", padding: 0 }}>
            <SortableList items={pages} onSortEnd={onSortEnd} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Button
          onClick={publishArticle}
          color={"primary"}
          variant={"contained"}
          endIcon={publishing && <LoadingIcon />}
          style={{ marginTop: 20 }}
        >
          Publish article
        </Button>
      </div>
    </Drawer>
  );
});
