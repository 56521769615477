import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { MenuList, MenuItem, WithStyles, Fade, Slide } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/styles";

interface OwnProps extends WithStyles {
  pages: any[];
}

export const DrawerMenu = withStyles({
  root: {
    margin: "-18px -24px",
    color: "#000",
    "& a": { color: "inherit", textDecoration: "none" },
    "& a.active $menuItem": {
      color: "#000"
    }
  },
  menuItem: {
    padding: "16px 24px",
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: ".78em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#000",
    "&.disabled": {
      color: "lightgray",
      cursor: "not-allowed"
    }
  }
})(
  observer(({ pages, classes }: OwnProps) => {
    return (
      <MenuList className={classes.root}>
        {pages.map((page, index) => (
          <NavLink to={page.path}>
            <Fade key={index} in style={{ transitionDelay: `${0.05 * index + 0.1}s` }}>
              <Slide
                in
                direction={"right"}
                style={{
                  transitionDelay: `${0.03 * index + 0.1}s`
                }}
              >
                <MenuItem className={`${classes.menuItem} ${page.disabled ? "disabled" : ""}`}>
                  {page.title}
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} color={"#000"} />
                </MenuItem>
              </Slide>
            </Fade>
          </NavLink>
        ))}
      </MenuList>
    );
  })
);
