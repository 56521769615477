import { Auth, graphqlOperation } from "aws-amplify";
import { GraphQLProviderProps } from "@coderehab/greenzine-react-deprecated";
import { GraphQLProvider } from "@coderehab/greenzine-react-deprecated";
import { removeNullValues } from "@coderehab/greenzine-react-deprecated";
import { CreateUser, DeleteUser, UpdateUser } from "./graphql/mutations";
import { QueryUser, QueryUserCollection } from "./graphql/queries";
import { User, UserModel, UserRecord } from "./user";

export interface UserProvider extends GraphQLProviderProps<User, UserRecord> {
  fetchAuthUser(): Promise<User>;
}

export class DefaultUserProvider extends GraphQLProvider<User, UserRecord> implements UserProvider {
  public model = UserModel;

  public async fetchAuthUser() {
    const auth = await Auth.currentSession();
    const currentUser = await Auth.currentAuthenticatedUser();
    const idToken = auth.getIdToken();

    const user: User = this.createInstance({ id: idToken.payload.sub });
    user.attributes = { ...idToken.payload, ...currentUser.attributes };

    return user;
  }

  protected listOperation = () => {
    return graphqlOperation(QueryUserCollection, {});
  };

  protected createOperation = (user: User) => {
    return graphqlOperation(CreateUser, { input: user });
  };

  protected fetchOperation = (user: User) => {
    return graphqlOperation(QueryUser, { id: user.id });
  };

  protected updateOperation = (user: User) => {
    return graphqlOperation(UpdateUser, {
      input: {
        id: user.id,
        username: user.username,
        attributes: removeNullValues(user.attributes),
      },
    });
  };

  protected deleteOperation = (user: User) => {
    return graphqlOperation(DeleteUser, { id: user.id });
  };
}
