import { data, GraphQLBase, GraphQLModel, RecordData } from "@coderehab/greenzine-react-deprecated";

export interface UserAttributes {
  email?: string;
  username?: string;
  phone_number?: string;
  address?: string;
  birthdate?: string;
  name?: string;
  middle_name?: string;
  family_name?: string;
  gender?: string;
  given_name?: string;
  locale?: string;
  groups?: string[];
  nickname?: string;
  picture?: string;
  preferred_username?: string;
  profile?: string;
  timezone?: string;
  updated_at?: string;
  website?: string;
}
export interface UserRecord extends RecordData {
  username: string;
  attributes: UserAttributes;
  login_history: string[];
  created_at: string;
  updated_at: string;
  status: string;
}

export interface User extends GraphQLModel<UserRecord>, UserRecord {}

export class UserModel extends GraphQLBase<UserRecord> implements User {
  @data public username = "Unknown";
  @data public created_at = "Unknown";
  @data public updated_at = "Unknown";
  @data public status = "Unknown";
  @data public attributes = {};
  @data public login_history = [];
}
