import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { CanView } from "../../content/components/can-view/can-view";
import Widget from "../../content/components/widget/widget";
import { DefaultLayout as Layout } from "../../layout/layout-default";
import { EditionPresenter } from "./edition-presenter";
import { TableEditions } from "./edition-table";
// import { MagazineDetailsForm } from "../magazine/partials/magazine-details-form";
import LoadingIcon from "../../content/components/loading";

type OwnProps = RouteComponentProps;

const UnauthorizedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        opacity: 0.3,
      }}
    >
      <Typography variant={"h1"} style={{ fontSize: "calc(2em + 1vw)" }}>
        Unauthorized
      </Typography>
      <Typography variant={"h5"}>you are not allowed to view this page</Typography>
    </div>
  );
};

@observer
class EditionOverviewComponent extends React.Component<OwnProps & PresenterProps<EditionPresenter>> {
  public render() {

    const { editionCollection, selectEdition, magazine, loading, createEdition, setPublic } = this.props.presenter;


    return (
      <Layout titlePrefix={magazine.title || "Magazine"} title={"editions"}>
        {loading ? (
          <LoadingIcon />
        ) : (
          <CanView notAuthorized={<UnauthorizedPage />}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Widget title="Edition overview">
                  <TableEditions
                    onRowSelect={selectEdition}
                    onEdit={selectEdition}
                    onCreate={createEdition}
                    onDelete={() =>{}}
                    onSetPublic={setPublic}
                    editions={editionCollection}
                  />
                </Widget>
              </Grid>
            </Grid>
          </CanView>
        )}
      </Layout>
    );
  }
}

export const PageEditionOverview = withRouter(
  withPresenter<EditionPresenter, RouteComponentProps>(
    (props, { interactor, provider }) =>
      new EditionPresenter(
        provider.magazine,
        provider.edition,
        interactor.json,
        interactor.user,
        interactor.interface,
        props
      ),
    EditionOverviewComponent
  )
);
