import * as React from "react";
import { EditStyleComponent } from "./edit-style-component";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { StyleEditorPresenter } from "./style-editor-presenter";
import { withPresenter, PresenterProps } from "../../../helpers/with-presenter";

interface OwnProps {
  selection: Array<keyof React.CSSProperties>;
  style: React.CSSProperties;
  onChange(style: React.CSSProperties): void;
}

export const Component = observer(
  ({ selection, style, presenter }: OwnProps & PresenterProps<StyleEditorPresenter>) => {
    const { onChange } = presenter;

    return (
      <Grid container spacing={2}>
        {selection.map((propertyName) => (
          <Grid item xs={12}>
            <EditStyleComponent
              property={propertyName}
              style={style}
              onChange={onChange}
              presenter={presenter}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
);

export const StyleEditor = withPresenter<StyleEditorPresenter, OwnProps>(
  ({ onChange }, { interactor }) => new StyleEditorPresenter(onChange, interactor.image),
  Component
);
