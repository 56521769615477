import { BaseModule, PrintInteractor } from "@coderehab/greenzine-react-deprecated";
import { DefaultUserInteractor, UserInteractor } from "../data/user/user-interactor";
import { BusinessInteractors, BusinessProviders } from "./business";
import { DefaultInterfaceInteractor, InterfaceInteractor } from "./interactor/interface-interactor";
import { DefaultElementInteractor, ElementInteractor } from "./interactor/element-interactor";

import { DefaultJSONInteractor, JSONInteractor } from "./interactor/json-interactor";
import {
  ImageSelectorInteractor,
  DefaultImageSelectorInteractor,
} from "./interactor/image-selector-interactor";

export class DefaultBusinessInteractors extends BaseModule<{}, BusinessInteractors, {}>
  implements BusinessInteractors {
  constructor(protected _providers: BusinessProviders) {
    super();
  }

  public get user(): UserInteractor {
    return this.loadInteractor("user", DefaultUserInteractor, this._providers.user);
  }

  public get interface(): InterfaceInteractor {
    return this.loadInteractor("interface", DefaultInterfaceInteractor, this.json);
  }

  public get element(): ElementInteractor {
    return this.loadInteractor("element", DefaultElementInteractor);
  }

  public get json(): JSONInteractor {
    return this.loadInteractor("json", DefaultJSONInteractor);
  }

  public get image(): ImageSelectorInteractor {
    return this.loadInteractor("image", DefaultImageSelectorInteractor);
  }

  public get print(): PrintInteractor {
    return this.loadInteractor("print", PrintInteractor);
  }
}
