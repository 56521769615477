import {
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Paper,
  Button,
  TextField,
} from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { CanView } from "../../content/components/can-view/can-view";
import { DefaultLayout as Layout } from "../../layout/layout-default";
import { MagazineConfigPresenter } from "./magazine-config-presenter";
import { useThemeCollection } from "@coderehab/greenzeen-themes";

type OwnProps = RouteComponentProps;

const UnauthorizedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        opacity: 0.3,
      }}
    >
      <Typography variant={"h1"} style={{ fontSize: "calc(2em + 1vw)" }}>
        Unauthorized
      </Typography>
      <Typography variant={"h5"}>you are not allowed to view this page</Typography>
    </div>
  );
};

@observer
class MagazineConfigComponent extends React.Component<
  OwnProps & PresenterProps<MagazineConfigPresenter>
> {
  public render() {
    const {
      magazine,
      changeTheme,
      changeTenant,
      changePoll,
      changePrint,
      changeGaCode,
      loading,
      save,
    } = this.props.presenter;

    if (loading) {
      return <Typography>Loading config</Typography>;
    }

    const themes = useThemeCollection();

    return (
      <Layout titlePrefix={"Magazine"} title={"overview"}>
        <CanView notAuthorized={<UnauthorizedPage />}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper style={{ padding: 20, margin: "auto", maxWidth: 460 }}>
                <InputLabel id="demo-customized-select-label">Theme</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  name={"theme"}
                  value={magazine.config.theme}
                  onChange={changeTheme}
                  placeholder="Select a theme"
                >
                  {themes.map((theme) => (
                    <MenuItem value={theme}>{theme}</MenuItem>
                  ))}
                </Select>
                <br /> <br />
                <Select
                  fullWidth
                  labelId="magazine-tenant"
                  id="magazine-tenant"
                  name={"tenant"}
                  value={magazine.config.tenant}
                  onChange={changeTenant}
                  placeholder="Select a tenant"
                >
                  <MenuItem value="default">
                    <em>Default</em>
                  </MenuItem>
                  <MenuItem value={"ncf"}>NCF</MenuItem>
                  <MenuItem value={"mst"}>MST</MenuItem>
                  <MenuItem value={"mst2020"}>MST2020</MenuItem>
                  <MenuItem value={"mst2021"}>MST2021</MenuItem>
                  <MenuItem value={"jasmijn"}>Jasmijn</MenuItem>
                  <MenuItem value={"carmel"}>Carmel</MenuItem> 
                  <MenuItem value={"coderehab"}>Code.Rehab</MenuItem>
                  <MenuItem value={"creativebastards"}>Creative Bastards</MenuItem>
                  <MenuItem value={"greenzeen"}>Greenzeen</MenuItem>
                  <MenuItem value={"twenteboard"}>Twenteboard</MenuItem>
                  <MenuItem value={"thorax2022"}>thorax2022</MenuItem>
                </Select>
                <br /> <br /> <br />
                <InputLabel id="demo-customized-select-label">Print?</InputLabel>
                <Checkbox
                  name={"print"}
                  checked={magazine.config.print === "true" ? true : false}
                  onChange={changePrint}
                />{" "}
                <br />
                <InputLabel id="demo-customized-select-label">Poll?</InputLabel>
                <Checkbox
                  name={"print"}
                  checked={magazine.config.poll === "true" ? true : false}
                  onChange={changePoll}
                />
                <br />
                Current GA: {magazine.config.gaCode} <br />
                <TextField
                  onChange={changeGaCode}
                  name={"gaCode"}
                  id="standard-basic"
                  label="gaCode"
                />{" "}
                <br /> <br />
                <br />
                <Button onClick={save} variant="contained" color="primary">
                  Save
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </CanView>
      </Layout>
    );
  }
}

export const PageMagazineConfig = withRouter(
  withPresenter<MagazineConfigPresenter, RouteComponentProps>(
    (props, { interactor, provider }) =>
      new MagazineConfigPresenter(provider.magazine, interactor.user, props),
    MagazineConfigComponent
  )
);
