import * as React from "react";

import { Typography, Button } from "@material-ui/core";

import { typographyVariants } from "../theme/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PagePresenter } from "./_base-page-presenter";
import { RouteComponentProps } from "react-router";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";

const Component = ({
  presenter
}: RouteComponentProps & PresenterProps<PagePresenter>) => (
  <>
    <img src="/images/logo.svg" height="36" alt="logo" />
    <br />
    <Typography variant={typographyVariants.h2} align="center" gutterBottom>
      We can't find the page you're looking for
    </Typography>
    <Typography variant={typographyVariants.body2} align="center">
      It may have moved or the link could be incorrect.
    </Typography>
    <Button
      onClick={presenter.back}
      variant="text"
      startIcon={<FontAwesomeIcon icon={["fal", "arrow-left"]} size="sm" />}
    >
      Previous page
    </Button>
  </>
);

export const Error404 = withPresenter<PagePresenter, RouteComponentProps>(
  props => new PagePresenter(props),
  Component
);
