import { observable } from "mobx";

interface CallbackHandlers {
  onCancel?: (selectedImage: string, key ?: string) => void;
  onChange?: (selectedImage: string, key ?: string) => void;
  onUpdate?: (selectedImage: string, key ?: string) => void;
}

export interface ImageSelectorInteractor {
  active: boolean;
  selectedImage: string;
  callback: CallbackHandlers;
  fieldKey: string | null;
  open(selectedImage: string, callbacks: CallbackHandlers, key?: string): void;
  cancel(): void;
  update(): void;
  change(): void;
}

export class DefaultImageSelectorInteractor implements ImageSelectorInteractor {
  @observable public active: boolean = false;
  @observable public selectedImage: string = "";
  
  public fieldKey: string | null = null;

  public callback: CallbackHandlers = {};

  public open = (selectedImage: string, callback: CallbackHandlers, key?: string) => {
    this.reset();
    this.callback = callback;
    this.selectedImage = selectedImage;
    if (key) {
      this.fieldKey = key;
    }
    this.active = true;
  };

  public cancel = () => {
    this._onChange();
    this._onCancel();
    this._close();
  };

  public update = () => {
    this._onChange();
    this._onUpdate();
    this._close();
  };

  public change = () => {
    this._onChange();
  };

  public reset = () => {
    this.callback = {};
    this.selectedImage = "";
  };

  protected _close = () => {
    this.reset();
    this.active = false;
  };

  private _onChange = () => {
    if (this.selectedImage !== "" && this.callback.onChange) {
      if (this.fieldKey) {
        console.log("Has fieldKey")
        this.callback.onChange(this.selectedImage, this.fieldKey)
      } else {
        console.log("No fieldkey")
        this.callback.onChange(this.selectedImage);
      }
    }
  };

  private _onCancel = () => {
    if (this.callback.onCancel && this.selectedImage !== "") {
      this.callback.onCancel(this.selectedImage);
    }
  };

  private _onUpdate = () => {
    if (this.callback.onUpdate && this.selectedImage !== "") {
      if (this.fieldKey) {
        console.log("Has fieldKey")
        this.callback.onUpdate(this.selectedImage, this.fieldKey);
      } else {
        console.log("No fieldkey")
        this.callback.onUpdate(this.selectedImage);
      }
    }
  };
}
