import * as React from "react";
import {
  Drawer,
  Button,
  Select,
  withStyles,
  Theme,
  WithStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { DataEditorPresenter } from "./data-editor-presenter";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { RenderElementForm } from "./data-forms";

interface OwnProps extends WithStyles<"drawerContent" | "sectionTitle"> {}

const styles = (theme: Theme) => ({
  drawerContent: {
    minWidth: 460,
    maxWidth: 460,
    "& > section": {
      padding: theme.spacing(2),
      borderBottom: "1px solid #eee",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  sectionTitle: {
    color: theme.palette.primary.main,
  },
});

const Component = observer(
  ({ presenter, classes }: OwnProps & PresenterProps<DataEditorPresenter>) => {
    const { update, cancel, data, switchComponent, active } = presenter;

    console.log("DATA",data);

    const [jsonEditor, setJsonEditor] = React.useState(false);

    return (
      <Drawer anchor="right" open={active ? true : false} onClose={cancel}>
        {active ? (
          <div className={classes.drawerContent}>
            <section>
              <Typography variant="h2">Element Editor</Typography>
            </section>
            <section>
              <Typography className={classes.sectionTitle} variant="h4">
                Component type
              </Typography>
              <Select fullWidth value={data.component} onChange={switchComponent}>
                <MenuItem value="Typography">Typography</MenuItem>
                <MenuItem value="Quote">Quote</MenuItem>
                <MenuItem value="ReadTime">Readtime</MenuItem>
                <MenuItem value="Image">Image</MenuItem>
                <MenuItem value="Video">Video</MenuItem>
                <MenuItem value="WistJeDat">WistJeDat</MenuItem>
                <MenuItem value="Author">Author</MenuItem>
                <MenuItem value="List">List</MenuItem>
                <MenuItem value="Popup">Popup</MenuItem>
                <MenuItem value="Button">Button</MenuItem>
                <MenuItem value="Index">Index</MenuItem>
                <MenuItem value="CoverButton">CoverButton</MenuItem>
              </Select>
            </section>
            <section>
              <Typography className={classes.sectionTitle} variant="h4" paragraph>
                {data.component} Configuration -{" "}
                <small onClick={() => setJsonEditor(!jsonEditor)}>
                  {jsonEditor ? "Json" : "Form"}
                </small>
              </Typography>

              <RenderElementForm data={data} presenter={presenter} forceJson={jsonEditor} />
            </section>
            <section>
              <Button onClick={update}>Update</Button>
            </section>
          </div>
        ) : null}
      </Drawer>
    );
  }
);

export const DataEditor = withStyles(styles)(
  withPresenter<DataEditorPresenter, OwnProps>(
    ({}, { interactor, provider }) =>
      new DataEditorPresenter(
        interactor.interface,
        interactor.element,
        interactor.image,
        provider.image
      ),
    Component
  )
);
