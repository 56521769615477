import { Magazine, MagazineProvider, Collection } from "@coderehab/greenzine-react-deprecated";
import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { IPresenter } from "../../../helpers/with-presenter";
import { AuthPagePresenter } from "../_base-auth-page-presenter";

export class MagazineConfigPresenter extends AuthPagePresenter implements IPresenter {
  @observable public selectedMagazine: Magazine | undefined = undefined;
  @observable public loading: boolean = false;

  public magazineId: string;
  public magazine: Magazine;

  @observable public config: any = {};

  // @computed get loading() {
  //   return this.collection.loading;
  // }

  @computed get collection(): Collection<Magazine> {
    return this._magazineProvider.collect();
  }

  @computed get magazines() {
    return this.collection.items;
  }

  constructor(
    protected _magazineProvider: MagazineProvider,
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps<{ magazine?: string }>
  ) {
    super(_userInteractor, _router);
    this.magazineId = this._router.match.params.magazine || "";
    this.magazine = this._magazineProvider.get(this.magazineId) as Magazine;
  }

  public mount = async () => {
    await this.collection.fetch();
    this.loading = true;
    await this.magazine.fetch();
    this.loading = false;

    this.config.theme = this.magazine.config.theme;
    this.config.print = this.magazine.config.print;
  };

  public unmount = () => {
    // Nope
  };

  public updateMagazine = (id: string) => {
    alert("CONNFIG");
    // this.redirect(route("app.magazine.config", { magazine: id }));
  };

  public changeTheme = (e: any) => {
    const value = e.target.value;
    this.config.theme = value;
    this.magazine.updateProperty("config", this.config);
  };

  public changeTenant = (e: any) => {
    const value = e.target.value;
    this.config.tenant = value;
    this.magazine.updateProperty("config", this.config);
  };

  public changePoll = (e: any) => {
    const value = e.target.checked ? "true" : "false";
    this.config.poll = value;
    this.magazine.updateProperty("config", this.config);
  };

  public changePrint = (e: any) => {
    const value = e.target.checked ? "true" : "false";
    this.config.print = value;
    this.magazine.updateProperty("config", this.config);
  };

  public changeGaCode = (e: any) => {
    console.log(e.target.value);
    const value = e.target.value;
    this.config.gaCode = value;
    // this.magazine.updateProperty("config",this.config);
  };

  public save = (e: any) => {
    console.log("TOTAL CONFIG", this.config);
    this.magazine.updateProperty("config", this.config);
    this.magazine.save();
  };
}
