import { observable } from "mobx";
import { Application } from "../../application";
import { InterfaceTools } from "../../../view/layout/partials/default-interface-presenter";

export interface JSONInteractor {
  activeJSON: Record<string, any>;
  onSave(): void;
  onSaveListener(listener: (data: Record<string, any>) => void): void;
  onChange(data: Record<string, any>): void;
}

export class DefaultJSONInteractor implements JSONInteractor {
  @observable public activeJSON: Record<string, any> = {};
  private _updatedJson: Record<string, any> | null = null;

  public onChange = (data: Record<string, any>) => {
    this._updatedJson = data;
  };

  public onSaveListener = (listener: (data: Record<string, any>) => void) => {
    // listener
    this._onSave = listener;
  };

  public onSave = () => {
    // console.log(data);
    if (this._updatedJson) {
      this._onSave(this._updatedJson.jsObject);
      Application.business.interactor.interface.activeTool = InterfaceTools.None;
    }
  };

  private _onSave = (data: Record<string, any>) => {
    // returm.//
  };
}
