import { StyleRules, Theme, withStyles, WithStyles } from "@material-ui/core";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import GridLayout, { WidthProvider, Layout } from "react-grid-layout";

import { LayoutEditorPresenter } from "./layout-editor-presenter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapEvent } from "../../../helpers/formatters";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { RenderElement, AnyElement } from "@coderehab/greenzeen-content";

const GridLayoutRenderer = WidthProvider(GridLayout);

interface OwnProps extends WithStyles<any> {
  elements: AnyElement[];
  layout: Layout[];
  onDelete?(id: string, data?: AnyElement[]): void;
  onReady?(editor: LayoutEditorPresenter): void;
  onUpdate(layout: Layout[], elements: AnyElement[]): void;
}

const styles = (theme: Theme): StyleRules => {
  return {
    page: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      background: "#fff",
      color: "#000",
    },

    editBtn: {
      position: "absolute",
      top: 8,
      right: 40,
      background: "#fff",
      color: "#333",
      fontSize: 14,
      width: 32,
      height: 32,
      borderRadius: "100%",
      display: "flex",
      zIndex: 1000,
      visibility: "hidden",
      cursor: "pointer",
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
      },
      "& > *": {
        margin: "auto",
      },
    },

    deleteBtn: {
      position: "absolute",
      top: 8,
      right: 5,
      background: "#bb1509",
      color: "#fff",
      fontSize: 14,
      width: 32,
      height: 32,
      borderRadius: "100%",
      display: "flex",
      zIndex: 1000,
      visibility: "hidden",
      cursor: "pointer",
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
      },
      "& > *": {
        margin: "auto",
      },
    },

    grid: {
      margin: "auto",
      flex: 1,
    },
    gridItem: {
      minHeight: 60,
      "& .react-resizable-handle": {
        color: "currentColor",
        opacity: 0,
        transition: "opacity  0.3s",
      },
      "&:before": {
        content: "' '",
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        background: "rgba(125,125,125,0.5)",
        border: "1px dashed",
        opacity: 0,
        transition: "opacity  0.3s",
      },
      "&:hover": {
        zIndex: 300,
        "& .react-resizable-handle": { opacity: 1 },
        "&:before": {
          opacity: 0.3,
        },

        "&:after": {
          content: "' '",
          position: "absolute",
          top: 0,
          height: 50,
          right: 0,
          left: 0,
          background: "currentColor",
          opacity: 0.1,
        },

        "& $editBtn": {
          visibility: "visible",
        },
        "& $deleteBtn": {
          visibility: "visible",
        },
      },
    },
  };
};

const Component = observer(({ presenter, classes }: OwnProps & PresenterProps<LayoutEditorPresenter>) => {
  const {
    elements,
    registerRef,
    rowHeight,
    cols,
    layout,
    margin,
    selectElement,
    removeElement,
    onLayoutChange,
    containerPadding,
  } = presenter;

  return (
    <GridLayoutRenderer
      className={classes.grid}
      layout={toJS(layout)}
      margin={margin}
      cols={cols}
      style={{ width: "100%" }}
      rowHeight={rowHeight}
      onLayoutChange={onLayoutChange}
      containerPadding={containerPadding}
    >
      {elements.map((element) => (
        <div key={element.id} className={classes.gridItem}>
          <div className={classes.editBtn} onClick={mapEvent(selectElement, element)}>
            <FontAwesomeIcon icon="pencil" />
          </div>
          <div className={classes.deleteBtn} onClick={mapEvent(removeElement, element.id)}>
            <FontAwesomeIcon icon="trash-alt" />
          </div>
          <div ref={(ref) => registerRef(element.id, ref)} style={{ padding: 1 }}>
            <RenderElement element={element} />
          </div>
        </div>
      ))}
    </GridLayoutRenderer>
  );
});

export const LayoutEditor = withStyles(styles)(
  withPresenter<LayoutEditorPresenter, OwnProps>(
    ({ layout, elements, onReady, onUpdate }, { interactor }) =>
      new LayoutEditorPresenter(layout, elements, interactor.element, interactor.print, onReady, onUpdate),
    Component
  )
);
