import { API, graphqlOperation } from "aws-amplify";
import { BaseModule } from "@coderehab/greenzine-react-deprecated";

export interface NetworkProviders {}

export interface NetworkModule extends NetworkProviders {
  fetch(querystring: string, variables?: Record<string, any>, useMock?: boolean): Promise<any>;
}

export class AdminNetworkModule extends BaseModule<NetworkProviders, {}, {}>
  implements NetworkModule {
  public init = async () => {};

  public fetch = async (querystring: string, variables?: any, useMock: boolean = false) => {
    let result;

    try {
      if (useMock) {
        // result = await FakeAPI.graphql(
        //   FakeGraphqlOperation(querystring, variables)
        // );
      } else {
        result = await API.graphql(graphqlOperation(querystring, variables));
      }
    } catch (err) {
      console.error(err);
      result = err;
    }
    return result;
  };
}
