import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { observable, computed } from "mobx";
import { RouteComponentProps } from "react-router";
import { Edition } from "../../../application/data/edition/edition";
import { EditionProvider } from "../../../application/data/edition/edition-provider";
import { Magazine } from "../../../application/data/magazine/magazine";
import { MagazineProvider } from "../../../application/data/magazine/magazine-provider";
import { User, UserRecord } from "../../../application/data/user/user";
import { route } from "../../../config/routes";
import { IPresenter } from "../../../helpers/with-presenter";
import { AuthPagePresenter } from "../_base-auth-page-presenter";
import { JSONInteractor } from "../../../application/business/interactor/json-interactor";
import { InterfaceInteractor } from "../../../application/business/interactor/interface-interactor";

export class EditionPresenter extends AuthPagePresenter implements IPresenter {
  @observable public selectedUser: User | null = null;
  @observable public loading: boolean = false;
  @observable public selectedEdition: Edition | undefined = undefined;

  public magazineId: string;
  public magazine: Magazine;

  @computed public get editionCollection(): Edition[] {
    return this.magazine.editions as Edition[];
  }

  @observable public createValues: UserRecord = {
    username: "",
    attributes: {},
  } as UserRecord;

  constructor(
    protected _magazineProvider: MagazineProvider,
    protected _editionProvider: EditionProvider,
    protected _JSONInteractor: JSONInteractor,
    protected _userInteractor: UserInteractor,
    protected _interfaceInteractor: InterfaceInteractor,
    protected _router: RouteComponentProps<{ magazine?: string }>
  ) {
    super(_userInteractor, _router);
    this.magazineId = this._router.match.params.magazine || "";
    this.magazine = this._magazineProvider.get(this.magazineId) as Magazine;
  }

  public mount = async () => {
    this.loading = true;
    await Promise.all([this.magazine.fetch()]);
    this._JSONInteractor.activeJSON = this.magazine.serialize();
    this._JSONInteractor.onSaveListener(this.saveJson);
    this.loading = false;
  };

  public unmount = () => {
    this._JSONInteractor.activeJSON = {};
  };

  public setPublic = async (id: string) => {
   
      const editionIndex = this.editionCollection.findIndex((edition) => edition.id === id);
      if (editionIndex !== -1) {
        const edition = this.editionCollection.splice(editionIndex, 1)[0];
        if (edition) {
          const value = (edition.public === "true") ? "false" : "true";
          const message = value === "true" ? "publish" : "unpublish";

          if(window.confirm("Are you sure to " + message + ' edition?')){        
          edition.updateProperty("public",value);
          await edition.save();
          await this.magazine.save();
        }
        // await this.magazine.save();
      }
    }

    return null;

 
  }

  public selectEdition = (id: string) => {
    this.redirect(
      route("app.edition.article.overview", {
        magazine: this.magazineId,
        edition: id,
      })
    );
  };

  public createEdition = async () => {
    const edition = this._editionProvider.create({
      magazineId: this.magazineId,
      title: "New Edition",
      articles: [],
    });
    await edition.save();
    await this.magazine.save();
  };

  public deleteEdition = async (id: string) => {
    const editionIndex = this.editionCollection.findIndex((edition) => edition.id === id);
    if (editionIndex !== -1) {
      const edition = this.editionCollection.splice(editionIndex, 1)[0];
      if (edition) {
        edition.delete();
      }
      await this.magazine.save();
    }
  };

  public selectUser = (id: string) => {};

  public resetForm = () => {};

  public saveJson = (data: any) => {
    if (this.magazine) {
      this.magazine.changes = data;
      this.magazine.save();
    }
  };
}
