import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { Page } from "../page/page";
import { Application } from "../../application";

export interface Article extends Greenzine.Article {}

export class ArticleModel extends Greenzine.ArticleModel implements Article {
  public get pageCollection(): Greenzine.Collection<Page> {
    const pageRecords = (this.record && this.record.pages) || [];
    const provider = Application.business.provider.page;

    return provider.collect(pageRecords) as any;
  }
}
