import { User } from "@coderehab/greenzine-react-deprecated";
import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { computed, observable } from "mobx";
import { InterfaceInteractor } from "../../../application/business/interactor/interface-interactor";
import { ToolConfig } from "../../../config/interface-tools";
import { routes } from "../../../config/routes";
import { IPresenter } from "../../../helpers/with-presenter";
import { JSONInteractor } from "../../../application/business/interactor/json-interactor";

export enum InterfaceTools {
  Menu = "menu",
  JSONeditor = "editor",
  Homepage = "homepage",
  Notifications = "notifications",
  PageInfo = "page-info",
  Support = "support",
  None = "not-set",
}

export interface InterfaceData {
  user: User | undefined;
  notifications: Notification[];
  pages: any[];
  currentJSON?: Record<string, any>;
}

export interface InterfaceActions {
  userLogout(): void;
  notificationSelect(): void;
  notificationDismiss(): void;
  onJsonSave(): void;
  onJsonChange(data: Record<string, any>): void;
}

export class InterfacePresenter implements IPresenter {
  @observable public config: Record<InterfaceTools, ToolConfig>;

  @computed public get data(): InterfaceData {
    return {
      user: this.user,
      notifications: [],
      pages: routes.filter((route) => route.mainmenu),
      currentJSON: this._JSONInteractor.activeJSON,
    };
  }
  // public data: InterfaceData;
  public actions: InterfaceActions;

  @computed public get user() {
    return this._userInteractor.currentUser;
  }

  public get activeTool() {
    return this._interfaceInteractor.activeTool;
  }

  public set activeTool(tool: InterfaceTools) {
    this._interfaceInteractor.activeTool = tool;
  }

  constructor(
    private _userInteractor: UserInteractor,
    private _interfaceInteractor: InterfaceInteractor,
    private _JSONInteractor: JSONInteractor
  ) {
    this.config = _interfaceInteractor.config;
    this.actions = {
      userLogout: this.logout,
      notificationDismiss: () => {},
      notificationSelect: () => {},
      onJsonChange: this._JSONInteractor.onChange,
      onJsonSave: this._JSONInteractor.onSave,
    };

    this.activeTool = InterfaceTools.None;
  }

  public mount() {
    // None
  }

  public unmount() {
    // None
  }

  public toggleTool = (tool: InterfaceTools) => {
    this.activeTool = this.activeTool !== tool ? tool : InterfaceTools.None;
  };

  public logout = () => {
    if (this.user) {
      this._userInteractor.logout();
    }
  };
}
