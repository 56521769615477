import { BaseModule } from "@coderehab/greenzine-react-deprecated";
import { RouteComponentProps } from "react-router";
import { BusinessModules, DefaultBusinessModule } from "./business/business";
import { NetworkModule, AdminNetworkModule } from "./network/network";
// import { PossibleRouterParams } from "../config/routes";

export interface IApplication {
  network: NetworkModule;
  business: BusinessModules;
  router: RouteComponentProps<any> | undefined;
}

class DefaultApplication
  extends BaseModule<
    {},
    {},
    {
      network: NetworkModule;
      business: BusinessModules;
    }
  >
  implements IApplication {
  public router: RouteComponentProps<any> | undefined = undefined;

  public get network(): NetworkModule {
    return this.loadModule("network", AdminNetworkModule);
  }

  public get business(): BusinessModules {
    return this.loadModule("business", DefaultBusinessModule, this.network);
  }
}

export const Application = new DefaultApplication();
