import { PollData, PollProvider } from "@coderehab/greenzine-react-deprecated";
import { computed, observable } from "mobx";
import { RouteComponentProps } from "react-router";
import { Poll } from "../../../application/data/poll/poll";
import { IPresenter } from "../../../helpers/with-presenter";

export class PollPresenter implements IPresenter {
  @observable public selectedPoll: Poll | null = null;
  @observable public createValues: PollData = {
    name: "",
    attributes: {},
  } as PollData;

  constructor(
    protected _pollProvider: PollProvider,
    protected _router: RouteComponentProps<{ design?: string }>
  ) {
    this.load();
  }

  public load = async () => {
    // this.loading = true;
    // await this._designInteractor.load();
    // this._designInteractor.select(this._router.match.params.design || "");
    // this.loading = false;
  };

  public mount = () => {};

  @computed public get allPolls() {
    return this._pollProvider.collect;
  }

  public findPoll = (id: string) => {
    return this._pollProvider.get(id);
  };

  public createPoll = (data: PollData) => {
    //
  };

  public updatePoll = (data: PollData) => {
    //
  };

  public unmount = () => {
    //
  };

  public selectPoll = (id: string) => {
    this.selectedPoll = this._pollProvider.get(id);
  };

  public resetForm = () => {
    this.selectedPoll = null;
    this.createValues = {
      name: "",
      attributes: {},
    } as PollData;
  };
}
