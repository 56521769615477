import * as React from "react";
import { DefaultStyleAttributeEditor } from "./default";
import { observer } from "mobx-react";
import { TextField } from "@material-ui/core";

@observer
export class BackgroundStyleEditor extends DefaultStyleAttributeEditor {
  public defaultValue = "";

  onStyleUpdate = (style: React.CSSProperties) => {
    this.props.onChange(style);
  };

  public render() {
    return (
      <>

<TextField
            fullWidth
            variant="standard"
            value={this.value}
            label={"Custom background"}
            onChange={this.onChange}
            />
      </>
    );
  }
}
