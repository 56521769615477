import React from "react";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OwnProps {
  title: string;
  icon?: IconName;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 2, 0, 2),
    background: theme.palette.white.main
  }
}));

export default function WidgetHeader({ title, icon }: OwnProps) {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      {icon && <FontAwesomeIcon icon={["fal", icon]} size="lg" />}
      <Typography variant={"h4"}>{title}</Typography>
    </section>
  );
}
