import {
  AnyElement,
  ElementType,
  createElement,
  TypographyElement,
  QuoteElement,
} from "@coderehab/greenzeen-content";

export const convert = (data: AnyElement) => ({
  from: (fromType: ElementType) => ({
    to: (toType: ElementType) => {
      const transformer = transformers[fromType + "To" + toType];
      const record = transformer ? transformer(data) : incompatible(data, toType);

      return createElement(record);
    },
  }),
});

const incompatible = (data: AnyElement, toType: ElementType): AnyElement => ({
  ...data,
  component: toType,
  config: {},
});

const TypographyToQuote = (data: TypographyElement): QuoteElement => {
  return {
    ...data,
    component: ElementType.Quote,
    config: { content: data.config.children },
  } as any;
};

const QuoteToTypography = (data: QuoteElement): TypographyElement => {
  return {
    ...data,
    component: ElementType.Typography,
    config: { children: data.config.content },
  } as any;
};

export const transformers = {
  TypographyToQuote,
  QuoteToTypography,
};
