import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { checkRedirect, routes } from "../config/routes";
import ScrollToTop from "../helpers/scroll-to-top";
import { AuthRoute } from "./auth-route";

export const Routes = observer(() => {
  const redirection = checkRedirect();

  if (redirection && redirection.to.includes("http")) {
    window.location.replace(redirection.to);
  }

  return redirection ? (
    <Redirect to={redirection.to} />
  ) : (
    <ScrollToTop>
      <Switch>
        {routes.map(route =>
          route.public ? (
            <Route
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          ) : (
            <AuthRoute
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          )
        )}
      </Switch>
    </ScrollToTop>
  );
});

export default withRouter(Routes);
