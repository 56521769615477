import * as React from "react";
import { Page } from "../../../application/data/page/page";
import { PageContainer } from "@coderehab/greenzine-react-deprecated";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { ArticlePagePresenter } from "./article-page-presenter";
import { PageLayout } from "./partials/page-layout";
import { StyleRules, Theme, withStyles, WithStyles, ThemeProvider } from "@material-ui/core";

import { PageSettings } from "./partials/page-settings";
import { PageHeader } from "./partials/page-header";
import { observer } from "mobx-react";

import classnames from "classnames";
import { useTheme } from "@coderehab/greenzeen-themes";
import { Ruler } from "./partials/ruler";

interface OwnProps
  extends WithStyles<
    | "root"
    | "previewRoot"
    | "fakeMagazineInterface"
    | "breakpointSM"
    | "breakpointMD"
    | "breakpointLG"
  > {
  page: Page;
  theme: string;
  onDelete(id: string): void;
}

const styles = (theme: Theme): StyleRules => ({
  root: {},
  previewRoot: {
    background: "#fff",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  },
  fakeMagazineInterface: {
    background: "#fff",
    height: 60,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
  breakpointSM: {
    margin: "auto !important",
    maxWidth: 420,
    minHeight: "720px !important",
    maxHeight: "720px ",
    padding: theme.spacing(10, 2),
    overflow: "auto",
    display: "block !important",
    fontSize: "0.5em",
  },
  breakpointMD: {
    margin: "auto !important",
    maxWidth: 1024,
    minHeight: "720px !important",
    maxHeight: "720px ",
    padding: theme.spacing(10, 2),
    overflow: "auto",
    fontSize: "0.75em",
  },
  breakpointLG: {},
});

const Component = observer(
  ({ presenter, classes }: OwnProps & PresenterProps<ArticlePagePresenter>) => {
    const {
      page,
      rulerActive,
      setEditor,
      onLayoutUpdated,
      isPreview,
      breakpoint,
      layout,
      elements,
    } = presenter;
    const { MuiTheme } = useTheme();

    return (
      <>
        <PageHeader presenter={presenter} />

        <ThemeProvider theme={MuiTheme}>
          <PageContainer
            classes={{
              root: classnames(classes.root, classes["breakpoint" + breakpoint.toUpperCase()]),
            }}
            page={page}
          >
            <Ruler active={rulerActive} />

            <PageLayout
              key={page.id + breakpoint}
              layout={layout}
              elements={elements}
              onEditorReady={setEditor}
              onLayoutUpdated={onLayoutUpdated}
            />
          </PageContainer>

          {isPreview && (
            <div className={classes.previewRoot} onClick={presenter.togglePreview}>
              <PageContainer page={page}>
                <PageLayout
                  layout={layout}
                  elements={elements}
                  preview={true}
                  onEditorReady={setEditor}
                  onLayoutUpdated={onLayoutUpdated}
                ></PageLayout>
                <div className={classes.fakeMagazineInterface}></div>
              </PageContainer>
            </div>
          )}
        </ThemeProvider>

        <PageSettings presenter={presenter} />
      </>
    );
  }
);

export const ArticlePage = withStyles(styles)(
  withPresenter<ArticlePagePresenter, OwnProps>(
    ({ page, theme }, { provider, interactor }) =>
      new ArticlePagePresenter(page, theme, provider.page, provider.magazine),
    Component
  )
);
