import { Button, TextField } from "@material-ui/core";
import { UserRecord } from "@coderehab/greenzine-react-deprecated";
import { observer } from "mobx-react";
import React from "react";
import { updateValue } from "../../../application/helpers";
import { mapEvent, nodeValue } from "../../../helpers/formatters";

interface OwnProps {
  user: UserRecord;
  isNew: boolean;
  onCreate(user: Partial<UserRecord>): void;
  onUpdate(user: Partial<UserRecord>): void;
  onCancel(): void;
}

export const FormCreateUser = observer(function({
  user,
  onCreate,
  onUpdate,
  onCancel,
  isNew,
}: OwnProps) {
  return (
    <section>
      <TextField
        required
        fullWidth
        margin="normal"
        placeholder="E-mail"
        id="username"
        label="Username"
        onChange={mapEvent(updateValue, user, "username", nodeValue)}
        value={user.username}
      />
      <TextField
        required
        fullWidth
        margin="normal"
        placeholder=""
        id="first-name"
        label="Name"
        onChange={mapEvent(updateValue, user.attributes, "name", nodeValue)}
        value={(user.attributes && user.attributes.name) || ""}
      />
      <TextField
        required
        fullWidth
        margin="normal"
        placeholder=""
        id="first-nsame"
        label="Last name"
        onChange={mapEvent(updateValue, user.attributes, "family_name", nodeValue)}
        value={(user.attributes && user.attributes.family_name) || ""}
      />
      <div style={{ marginTop: 12 }}>
        {isNew ? (
          <Button onClick={mapEvent(onCreate, user)}>Create User</Button>
        ) : (
          <>
            <Button onClick={mapEvent(onUpdate, user)}>Update User</Button>
            <Button style={{ marginLeft: 12 }} color={"secondary"} onClick={mapEvent(onCancel)}>
              Cancel
            </Button>
          </>
        )}
      </div>
    </section>
  );
});
