import * as Greenzine from "@coderehab/greenzine-react-deprecated";
import { Application } from "../../application";
import { Article } from "../article/article";

export interface Edition extends Greenzine.Edition {
  articles: Article[];
  articleCollection: Greenzine.Collection<Article>;
}

export class EditionModel extends Greenzine.EditionModel implements Edition {
  public get articleCollection(): Greenzine.Collection<any> {
    const provider = Application.business.provider.article;
    return provider.collect(this.record.articles);
  }

  public get articles(): Article[] {
    return this.articleCollection.items;
  }

  public serialize = () => {
    const result = { ...this._values };
    result.articles =
      (this.record.articles && (this.record.articles.map((article) => article.id) as any)) || [];
    delete result.magazineId;
    return result;
  };
}
