import * as React from "react";

import { Typography } from "@material-ui/core";

import { DefaultLayout as Layout } from "../layout/layout-default";

export class TermsOfService extends React.Component {
  public render() {
    return (
      <Layout title="Terms of service">
        <Typography variant="body1">Some content</Typography>
      </Layout>
    );
  }
}
