import { UserInteractor } from "@coderehab/greenzine-react-deprecated";
import { computed } from "mobx";
import { IPresenter } from "../../../../helpers/with-presenter";

export class CanViewPresenter implements IPresenter {
  @computed public get isAdmin() {
    return true;
  }

  public mount = () => {};

  public unmount = () => {};

  constructor(protected _userInteractor: UserInteractor) {}
}
