import * as React from "react";
import JSONInput from "react-json-editor-ajrm";
import { WithStyles } from "@material-ui/core";
import { observer } from "mobx-react";

import { withStyles } from "@material-ui/styles";

interface OwnProps extends WithStyles {
  currentJSON: Record<string, any> | undefined;
  onChange(data: Record<string, any>): void;
  onSave(): void;
}

const classes = {
  editor: {
    maxWidth: "100%"
  }
};

export const DrawerJsonEditor = withStyles({ classes })(
  observer(({ currentJSON, classes, onChange, onSave }: OwnProps) => {
    return (
      <div>
        <JSONInput
          onChange={onChange}
          width="100%"
          height="100%"
          id="a_unique_id"
          placeholder={currentJSON}
          className={classes.editor}
        />
        <button onClick={onSave}>Save</button>
      </div>
    );
  })
);
