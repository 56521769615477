import { createMuiTheme } from "@material-ui/core";
import { Theme, ThemeOptions } from "@material-ui/core";
import { themeConfig, typography } from "./config";
import { MuiButton, MuiIconButton } from "./overrides/button";

function createGreenzeenTheme(options: ThemeOptions): Theme {
  return createMuiTheme({
    spacing: themeConfig.spacing,
    typography: {
      fontFamily: "IBM Plex Sans",
      allVariants: {},
      body1: {
        ...typography("body1"),
      },
      body2: {
        ...typography("body2"),
      },
      h1: {
        ...typography("h1"),
      },
      h2: {
        ...typography("h2"),
      },
      h3: {
        ...typography("h3"),
      },
      h4: {
        ...typography("h4"),
      },
      h5: {
        ...typography("h5"),
      },
      h6: {
        ...typography("h6"),
      },
      button: { ...typography("button") },
    },
    palette: {
      primary: {
        main: "#073B3A",
      },
      secondary: {
        main: "#778DA9",
      },
      white: {
        main: "#fff",
      },
      background: {
        default: "#fff",
      },
    },
    shape: {
      borderRadius: 0,
    },
    props: {
      MuiCard: {
        elevation: 0,
      },
      MuiButton: {
        variant: "contained",
        color: "primary",
        disableElevation: true,
      },
      MuiExpansionPanel: {
        elevation: 0,
      },
      MuiInputLabel: {
        // shrink: true
      },
    },
    overrides: {
      MuiButton: MuiButton,
      MuiIconButton: MuiIconButton,
    },
  });
}

export const theme = createGreenzeenTheme({});
