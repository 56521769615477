import { BaseModule, PageProvider } from "@coderehab/greenzine-react-deprecated";
import AdminArticleProvider, { ArticleProvider } from "../data/article/article-provider";
import AdminEditionProvider, { EditionProvider } from "../data/edition/edition-provider";
import AdminMagazineProvider, { MagazineProvider } from "../data/magazine/magazine-provider";
import { AdminPollProvider, PollProvider } from "../data/poll/poll-provider";
import { DefaultUserProvider, UserProvider } from "../data/user/user-provider";
import { NetworkModule } from "../network/network";
import { BusinessProviders } from "./business";
import { AdminPageProvider } from "../data/page/page-provider";
import AdminImageProvider, { ImageProvider } from "../data/image/image-provider";

export class DefaultBusinessProviders extends BaseModule<BusinessProviders, {}, {}>
  implements BusinessProviders {
  constructor(protected _network: NetworkModule) {
    super();
  }

  public get magazine(): MagazineProvider {
    return this.loadProvider("magazine", AdminMagazineProvider, this._network);
  }

  public get page(): PageProvider {
    return this.loadProvider("page", AdminPageProvider, this._network);
  }

  public get article(): ArticleProvider {
    return this.loadProvider("article", AdminArticleProvider, this._network);
  }

  public get edition(): EditionProvider {
    return this.loadProvider("edition", AdminEditionProvider, this._network);
  }

  public get user(): UserProvider {
    return this.loadProvider("user", DefaultUserProvider, this._network);
  }

  public get poll(): PollProvider {
    return this.loadProvider("poll", AdminPollProvider, this._network);
  }

  public get image(): ImageProvider {
    return this.loadProvider("image", AdminImageProvider, this._network);
  }
  
}
