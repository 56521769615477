import { IPresenter } from "../../../helpers/with-presenter";
import { ElementInteractor } from "../../../application/business/interactor/element-interactor";
import { computed } from "mobx";
import { convert } from "./transformers/transformers";
import { ImageProvider } from "../../../application/data/image/image-provider";
import { ImageSelectorInteractor } from "../../../application/business/interactor/image-selector-interactor";
import { InterfaceInteractor } from "../../../application/business/interactor/interface-interactor";
import { AnyElement } from "@coderehab/greenzeen-content";

export class DataEditorPresenter implements IPresenter {
  constructor(
    protected _interfaceInteractor: InterfaceInteractor,
    protected _elementInteractor: ElementInteractor,
    protected _imageSelectorInteractor: ImageSelectorInteractor,
    protected _imageProvider: ImageProvider
  ) {
    //
    // this.demoImageList();
  }

  public selectImage = (selectedImage: any, key: string) => {
    console.log("selectImage");
    this._imageSelectorInteractor.open(
      selectedImage,
      {
        onUpdate: this.onImageChange,
      },
      key
    );
  };

  public onImageChange = (selectedImage: string, key: string | undefined) => {
    if (key) {
      this.updateProp(key, selectedImage);
    }
  };

  public get activeTheme() {
    return this._interfaceInteractor.theme;
  }

  // private demoImageList = async () => {
  //   await this._imageProvider.fetchList();
  //   console.log(this._imageProvider.collect().items);
  // };

  @computed public get active(): boolean {
    return this._elementInteractor.active;
  }

  @computed public get data(): AnyElement {

    console.log("DATA IN PRES.",this._elementInteractor.data);

    // if (this.active && !this._elementInteractor.data) {
    //   throw new Error("Data is required");
    // }

    return this._elementInteractor.data || this._elementInteractor.createElement();
  }

  public mount = () => {};
  public unmount = () => {};
  public cancel = this._elementInteractor.cancel;
  public update = this._elementInteractor.update;

  public updateProp = (key: string, value: any) => {
    this.data.config[key] = value;
    this._elementInteractor.change();
  };

  public updateElement = (element: AnyElement) => {
    console.log("updateElement",element);

    this._elementInteractor.data = element;
    this._elementInteractor.change();
  };

  public switchComponent = (e: any) => {
    this._elementInteractor.data = convert(this.data)
      .from(this.data.component as any)
      .to(e.target.value);
    this._elementInteractor.change();
  };
}
