import * as React from "react";
import { observer } from "mobx-react";
import { TextField } from "@material-ui/core";
import { StyleEditorProps } from "../edit-style-component";
import { observable, computed } from "mobx";
import { StyleEditorPresenter } from "../style-editor-presenter";

@observer
class Component extends React.Component<StyleEditorProps, {}> {
  public presenter: StyleEditorPresenter;
  public defaultValue: string | number = "inherit";

  @observable public property: keyof React.CSSProperties;

  @observable public _value: number | string;

  @computed public get value() {
    return this._value || this.style[this.property];
  }

  @computed public get style() {
    return this.props.style;
  }

  constructor(props: StyleEditorProps) {
    super(props);
    this.presenter = props.presenter;
    this.property = props.property;
    this._value = props.style[props.property];
  }

  public componentDidMount = () => {
    if (!this.value && this.value !== 0) {
      this._value = this.defaultValue;
    }
  };

  public updateValue = (value: any) => {
    value = isNaN(+value) ? value : +value;
    this._value = this.style[this.property] = value || "";
    this.props.onChange(this.style);
  };

  public onChange = (e: React.ChangeEvent<HTMLInputElement | { value: any }>) => {
    this.updateValue(e.target.value);
  };

  public render() {
    return (
      <TextField
        fullWidth
        variant="standard"
        value={this.value}
        label={this.property}
        onChange={this.onChange}
      ></TextField>
    );
  }
}

export const DefaultStyleAttributeEditor = Component;
