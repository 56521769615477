import * as React from "react";
import { BackgroundImageStyleEditor } from "./style-editors/background-image";
import { DefaultStyleAttributeEditor } from "./style-editors/default";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import { StyleEditorPresenter } from "./style-editor-presenter";
import { BackgroundRepeatStyleEditor } from "./style-editors/background-repeat";
import { BackgroundSizeStyleEditor } from "./style-editors/background-size";
import { BackgroundPositionStyleEditor } from "./style-editors/background-position";
import { MaxWidthPositionStyleEditor } from "./style-editors/max-width";
import { SpacingStyleEditor } from "./style-editors/spacing";
import { ColorStyleEditor } from "./style-editors/color";
import { BackgroundStyleEditor } from "./style-editors/background";

export interface StyleEditorProps {
  property: keyof React.CSSProperties;
  style: any;
  presenter: StyleEditorPresenter;
  onChange(style: React.CSSProperties): void;
}

const styleEditors: Partial<Record<
  keyof React.CSSProperties | "default",
  React.ComponentClass<StyleEditorProps, {}>
>> = {
  color: ColorStyleEditor,
  backgroundColor: ColorStyleEditor,
  backgroundImage: BackgroundImageStyleEditor,
  backgroundRepeat: BackgroundRepeatStyleEditor,
  background: BackgroundStyleEditor,
  backgroundSize: BackgroundSizeStyleEditor,
  backgroundPosition: BackgroundPositionStyleEditor,
  maxWidth: MaxWidthPositionStyleEditor,
  padding: SpacingStyleEditor,
  margin: SpacingStyleEditor,
  default: DefaultStyleAttributeEditor,
};

export const EditStyleComponent = observer(
  ({ property, style, onChange, presenter }: StyleEditorProps) => {
    const Editor = styleEditors[property] || styleEditors["default"];

    if (Editor) {
      return <Editor property={property} style={style} onChange={onChange} presenter={presenter} />;
    }

    return <Typography>Style editor "{property}" not available</Typography>;
  }
);
