import {
  BaseModule,
  EditionProvider,
  NetworkModule,
  UserInteractor,
  UserProvider,
} from "@coderehab/greenzine-react-deprecated";
import { ArticleProvider } from "../data/article/article-provider";
import { MagazineProvider } from "../data/magazine/magazine-provider";
import { PollProvider } from "../data/poll/poll-provider";
import { InterfaceInteractor } from "./interactor/interface-interactor";
import { DefaultBusinessInteractors } from "./interactors";
import { DefaultBusinessProviders } from "./providers";
import { ElementInteractor } from "./interactor/element-interactor";
import { JSONInteractor } from "./interactor/json-interactor";
import { PageProvider } from "@coderehab/greenzine-react-deprecated";
import { ImageProvider } from "../data/image/image-provider";
import { ImageSelectorInteractor } from "./interactor/image-selector-interactor";
import { PrintInteractor } from "./interactor/print-interactor";

export interface BusinessInteractors {
  user: UserInteractor;
  interface: InterfaceInteractor;
  element: ElementInteractor; 
  json: JSONInteractor;
  image: ImageSelectorInteractor;
  print: PrintInteractor
}

export interface BusinessProviders {
  article: ArticleProvider;
  magazine: MagazineProvider;
  edition: EditionProvider;
  page: PageProvider;
  user: UserProvider;
  poll: PollProvider;
  image: ImageProvider;
}

export interface BusinessModules {
  interactor: BusinessInteractors;
  provider: BusinessProviders;
}

export class DefaultBusinessModule extends BaseModule<{}, {}, BusinessModules>
  implements BusinessModules {
  constructor(private _network: NetworkModule) {
    super();
  }

  public get interactor(): BusinessInteractors {
    return this.loadModule("interactor", DefaultBusinessInteractors, this.provider);
  }


  public get provider(): BusinessProviders {
    return this.loadModule("provider", DefaultBusinessProviders, this._network);
  }

  public get mockMode(): boolean {
    return false;
  }

  public get printLayout(): boolean {
    return false;
  } 
}
