import { makeStyles, Theme } from "@material-ui/core";
import { User } from "@coderehab/greenzine-react-deprecated";
import * as React from "react";
import { PageTitles } from "./partials/page-titles";

interface OwnProps {
  title?: string;
  subTitle?: string;
  user?: User;
  titlePrefix?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 36,
  },
}));

export default function DefaultHeader(props: OwnProps) {
  const classes = useStyles();
  const { title, subTitle, titlePrefix } = props;

  return (
    <header className={classes.root}>
      {(title || subTitle) && <PageTitles prefix={titlePrefix} title={title} subtitle={subTitle} />}
    </header>
  );
}
