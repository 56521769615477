import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { CanView } from "../../content/components/can-view/can-view";
import { TablePolls } from "../../content/components/table-polls";
import Widget from "../../content/components/widget/widget";
import { DefaultLayout as Layout } from "../../layout/layout-default";
import { PollPresenter } from "./poll-presenter";

type OwnProps = RouteComponentProps;

const UnauthorizedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        opacity: 0.3
      }}
    >
      <Typography variant={"h1"} style={{ fontSize: "calc(2em + 1vw)" }}>
        Unauthorized
      </Typography>
      <Typography variant={"h5"}>
        you are not allowed to view this page
      </Typography>
    </div>
  );
};

@observer
class pollOverviewComponent extends React.Component<
  OwnProps & PresenterProps<PollPresenter>
> {
  public render() {
    const { selectedPoll, selectPoll } = this.props.presenter;

    return (
      <Layout titlePrefix={"Greenzine"} title={"Poll"} subTitle={"overview"}>
        <CanView notAuthorized={<UnauthorizedPage />}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8} lg={9}>
              <Widget title="Poll overview">
                <TablePolls
                  onCreate={() => {}}
                  onRowSelect={() => {}}
                  onEdit={selectPoll}
                  onDelete={() => {}}
                  poll={selectedPoll}
                />
              </Widget>
            </Grid>

            {/* <Grid item xs={"auto"} md={4} lg={3}>
              <Widget title={selectedPoll ? "Update user" : "Create user"}>
                <FormCreatePoll
                  isNew={selectedPoll ? false : true}
                  user={selectedPoll ? selectedPoll : createValues}
                  onCreate={createPoll}
                  onUpdate={updatePoll}
                  onCancel={resetForm}
                />
              </Widget>
            </Grid> */}
          </Grid>
        </CanView>
      </Layout>
    );
  }
}

export const PollOverview = withRouter(
  withPresenter<PollPresenter, RouteComponentProps>(
    (props, { provider }) => new PollPresenter(provider.poll, props),
    pollOverviewComponent
  )
);
