import { LayoutRendererPresenter } from "@coderehab/greenzine-react-deprecated";
import { toJS } from "mobx";
import { Layout } from "react-grid-layout";
import { ElementInteractor } from "../../../application/business/interactor/element-interactor";
import { AnyElement } from "@coderehab/greenzeen-content";
import { PrintInteractor } from "../../../application/business/interactor/print-interactor";

export class LayoutEditorPresenter extends LayoutRendererPresenter {
  constructor(
    _layout: Layout[],
    _elements: AnyElement[],
    protected _elementInteractor: ElementInteractor,
    protected _printerInteractor: PrintInteractor,
    protected _onReady?: (editor: LayoutEditorPresenter) => void,
    protected _onUpdate?: (layout: Layout[], elements: AnyElement[]) => void
  ) {
    super(_layout, _elements, _printerInteractor);
  }

  mount = () => {
    this.setup();
    if (this._onReady) {
      this._onReady(this);
    }
  };

  unmount = () => {
    //
  };

  public selectElement = (element: AnyElement) => {
    this._elementInteractor.open(element, {
      onChange: this.onElementChange,
    });
  };

  public createElement = () => {
    const element = this._elementInteractor.createElement();
    this._elements.splice(0, 0, element);
    this._layout.splice(0, 0, {
      i: element.id,
      w: this.cols,
      h: -1,
      x: -1,
      y: -1,
    });

    this.selectElement(this._elements[0]);
    return element;
  };

  public removeElement = (id: any) => {
    const index = this._elements.findIndex((d) => id === d.id);
    this._elements.splice(index, 1);
    this.onLayoutChange();
  };

  public onElementChange = (data: any) => {
    const index = this._elements.findIndex((d) => data.id === d.id);
    this._elements.splice(index, 1, data);
    this.onLayoutChange();
  };

  public onLayoutChange = (layouts?: Layout[]) => {
    this.updateElementSizes();

    if (layouts) {
      this._layout = layouts;
    }

    if (this._onUpdate) {
      this._onUpdate(toJS(this._layout), this.elements);
    }
  };
}
