import { ButtonClassKey } from "@material-ui/core/Button";
import { FabClassKey } from "@material-ui/core/Fab";
import { IconButtonClassKey } from "@material-ui/core/IconButton";
import { LinkClassKey } from "@material-ui/core/Link";
import { CSSProperties } from "@material-ui/styles";

import { typography, spacing } from "../config";
import { SwitchClassKey } from "@material-ui/core/Switch";

export const MuiLink: Partial<Record<LinkClassKey, CSSProperties>> = {
  root: {
    ...typography("button"),
    color: "#000",
    "&:hover": {}
  }
};

export const MuiFab: Partial<Record<FabClassKey, CSSProperties>> = {
  root: {
    ...typography("button"),
    color: "#000",
    boxShadow: "none"
  },
  primary: {
    backgroundColor: "#000",
    "&:hover": { backgroundColor: "#000" }
  },
  secondary: {
    backgroundColor: "#000",
    "&:hover": { backgroundColor: "#000" }
  },
  sizeSmall: {
    width: 36,
    height: 36
  }
};

export const MuiSwitch: Partial<Record<SwitchClassKey, CSSProperties>> = {
  switchBase: {
    height: 38,
    width: 38
  }
};

export const MuiIconButton: Partial<Record<
  IconButtonClassKey,
  CSSProperties
>> = {
  root: {
    height: 48,
    width: 48,
    backgroundColor: "transparent",
    "&:hover, $active": {
      color: "#000",
      backgroundColor: "transparent"
    }
  },
  colorSecondary: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  label: { width: 18, height: 18 }
};

export const MuiButton: Partial<Record<ButtonClassKey, CSSProperties>> = {
  root: {
    padding: spacing(0, 2),
    height: spacing(4),
    borderRadius: 0,
    position: "relative",
    textDecoration: "none !important",
    display: "inline-flex",
    overflow: "hidden",

    "&:hover": {
      boxShadow: "none !important"
    },

    "&:hover:before": {
      transform: "rotate(0deg)",
      top: 0
    },

    "& $label": {
      position: "relative"
    }
  },

  sizeSmall: {
    padding: spacing(0, 1),
    height: spacing(3)
  }
};
