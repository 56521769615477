import {
  StyleRules,
  TableCell,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { PollQuestion } from "@coderehab/greenzine-react-deprecated";
import * as React from "react";
import { Poll } from "../../../application/data/poll/poll";
import { PresenterProps } from "../../../helpers/with-presenter";
import { PollSinglePresenter, ProcessedAnswer } from "../../pages/poll/poll-single-presenter";
import { PollQuestionResult } from "./poll-question-result";

interface OwnProps extends PresenterProps<PollSinglePresenter>, WithStyles<any> {
  question: PollQuestion;
  currentPoll: Poll;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    padding: theme.spacing(1),
  },
  inner: {
    marginTop: theme.spacing(1),
  },
});

const PollQuestionComponent = (props: OwnProps) => {
  const { classes, question, presenter } = props;

  const result: ProcessedAnswer = presenter.results[question.id];

  return (
    <TableRow>
      <TableCell className={classes.root}>
        <Typography variant="h5">
          {question.question} ({result && result.totalSubmissions} inzendingen)
        </Typography>
        <div className={classes.inner}>{<PollQuestionResult result={result} />}</div>
      </TableCell>
    </TableRow>
  );
};

export const PollQuestionBlock = withStyles(styles)(PollQuestionComponent);
