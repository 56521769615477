import { PageFragment } from "../../page/graphql/fragments";

export const ArticleFragment = `
  id
  image
  backgroundImage
  title
  content
  type
  index
  slug
  color
  featuredImage
  style
  pages {
   ${PageFragment}
  }
  pageOrder
`;
